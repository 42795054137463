export const formatDateShort = (date: Date): string => {
  const now = new Date()
  const isToday = date.getDate() === now.getDate()

  if (isToday) {
    return "Today"
  }

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  })
}

export const formatDateFull = (date: Date): string => {
  const now = new Date()
  const isToday = date.getDate() === now.getDate()

  if (isToday) {
    return (
      "Today at " +
      date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      })
    )
  }

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  })
}

export const timestampToDate = (timestamp: number): Date => {
  return new Date(timestamp * 1000)
}
