import { useMemo } from "react"
import { launchpadCollectionsService } from "@/services/launchpad-collections-service"
import { useQuery } from "react-query"

export const useLaunch = (slug?: string) => {
  const queryResult = useQuery(
    ["launchpadCollection", slug],
    async () => {
      if (!slug) return
      return launchpadCollectionsService.getLaunchpadCollectionDetails(slug!)
    },
    {
      enabled: !!slug,
    }
  )

  return useMemo(() => {
    return {
      launchpadCollection: queryResult.data,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
    }
  }, [queryResult.data, queryResult.error, queryResult.isLoading])
}
