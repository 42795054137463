import { Task, TaskProvider, TaskType } from "@/types/task"

export const tasksMock: Task[] = [
  {
    id: "1",
    type: TaskType.Connect,
    provider: TaskProvider.Venom,
    reward: 100,
    name: "Login with your Venom Wallet",
    description:
      "Sign in with your Venom Wallet to receive your first 50 Gems.",
    externalLink: "https://venomwallet.com/",
    buttonText: "Connect",
    isActive: true,
  },
  {
    id: "2",
    type: TaskType.Subscribe,
    provider: TaskProvider.Email,
    reward: 100,
    name: "Add your email address",
    description:
      "Add and verify your email address to join our mailing list and get your 100 Gems.",
    requiredTasks: ["1"],
    buttonText: "Check",
    isActive: true,
  },
  {
    id: "3",
    type: TaskType.Mint,
    provider: TaskProvider.Venom,
    reward: 200,
    name: "Mint the Gateway Pass NFT",
    description:
      "Mint your Gateway Pass NFT to unlock the 100 Gems that await you.",
    requiredTasks: ["1"],
    externalLink: "/launchpad/gateway-mint-pass",
    buttonText: "Check",
    isActive: true,
  },
]
