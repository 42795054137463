import { Faq, FaqCategory } from "@/types/faq"

export const faqMock: Faq[] = [
  {
    title: "Getting started",
    description: "All the basics so you can mint your first NFT",
    category: FaqCategory.Profile,
    entries: [
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
    ],
  },
  {
    title: "Venom wallet",
    description: "How to create and use Venom wallet",
    category: FaqCategory.Venom,
    entries: [
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
    ],
  },
  {
    title: "Trust and safety",
    description: "Learn about how we keep our launchpad safe",
    category: FaqCategory.Safety,
    entries: [
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
    ],
  },
  {
    title: "General",
    description: "General questions and common problems",
    category: FaqCategory.General,
    entries: [
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
      {
        question: "Lorem ipsum?",
        answer: "Lorem ipsum sit dolor amet sit do amet",
      },
    ],
  },
]
