import * as React from "react"
import { LoaderIcon, LucideProps } from "lucide-react"

import { cn } from "@/utils/tw-utils"

export interface SpinnerProps extends LucideProps {}

const Spinner = React.forwardRef<SVGSVGElement, SpinnerProps>(
  ({ className, ...props }, ref) => {
    return (
      <LoaderIcon
        className={cn("h-5 w-5 animate-spin", className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Spinner.displayName = "Spinner"

export { Spinner }
