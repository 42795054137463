import { useMemo } from "react"

import { ExternalLinkType } from "@/types/external-link"
import { LaunchpadCollectionWithDetails } from "@/types/launchpad-collection"

import { shortenAddress } from "@/utils/venom-utils"
import { ExternalLinks } from "@/components/ui/external-links"
import { StyledHtml } from "@/components/ui/styled-html"

type LaunchDescriptionProps = {
  collection: LaunchpadCollectionWithDetails
}

const LaunchDescription = ({ collection }: LaunchDescriptionProps) => {
  const hasRichDescription = Boolean(
    collection.richDescription && collection.richDescription.length
  )

  const externalLinks = useMemo(() => {
    const isDeployed = Boolean(collection.tvm.contractAddress)

    if (!isDeployed) return collection.links

    return [
      ...collection.links,
      {
        title: `Contract ${shortenAddress(collection.tvm.contractAddress)}`,
        url: `https://testnet.venomscan.com/accounts/${collection.tvm.contractAddress}`,
        type: ExternalLinkType.Contract,
      },
    ]
  }, [collection.links, collection.tvm.contractAddress])

  return (
    <>
      <h3 className="text-2xl font-bold">{collection.name}</h3>

      <ExternalLinks
        links={externalLinks}
        _link={{
          className: "h-9 px-3",
        }}
        className="mt-3"
      />

      {hasRichDescription && (
        <StyledHtml
          html={collection.richDescription}
          className="mt-8 text-sm"
        />
      )}
    </>
  )
}
LaunchDescription.displayName = "LaunchDescription"

export { LaunchDescription }
