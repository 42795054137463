import React, { useState } from "react"
import { launchpadCollectionsService } from "@/services/launchpad-collections-service"
import { SearchIcon } from "lucide-react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { useDebounce } from "usehooks-ts"

import { LaunchpadCollection } from "@/types/launchpad-collection"
import { MediaSize } from "@/types/media"

import { cn } from "@/utils/tw-utils"
import { buttonVariants } from "@/components/ui/button"
import { Input, InputProps } from "@/components/ui/input"
import { Media } from "@/components/ui/media"
import { popoverStyle } from "@/components/ui/popover"
import { Spinner } from "@/components/ui/spinner"

interface SearchProps extends InputProps {}

const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  ({ className, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const debouncedIsFocused = useDebounce(isFocused, 10)

    const [search, setSearch] = useState("")
    const debouncedSearch = useDebounce(search, 250)

    const isEnabled =
      debouncedIsFocused &&
      Boolean(debouncedSearch && debouncedSearch.trim().length > 1)

    const { data: collections, isFetching } = useQuery(
      ["launchpadCollections", debouncedSearch],
      () =>
        launchpadCollectionsService.getLaunchpadCollections({
          search: debouncedSearch,
        }),
      { enabled: isEnabled, keepPreviousData: true }
    )

    const hasResults = collections && collections.length > 0

    return (
      <div className={cn("relative", className)}>
        <div
          className={cn("relative w-full", {
            "z-50": isFocused,
          })}
        >
          <Input
            ref={ref}
            className="peer pl-10"
            placeholder="Search collections"
            value={search}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setSearch(e.target.value)}
            {...props}
          />

          <div className="absolute left-3 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center text-foreground/60 peer-focus:text-foreground">
            {isFetching ? <Spinner /> : <SearchIcon className="h-5 w-5" />}
          </div>
        </div>

        {isEnabled && hasResults && (
          <div
            data-state="open"
            data-side="bottom"
            className={cn(
              popoverStyle,
              "absolute left-0 right-0 top-0 z-40 w-full overflow-hidden p-0 pt-10"
            )}
          >
            {collections?.map((collection) => (
              <SearchResult key={collection.id} collection={collection} />
            ))}
          </div>
        )}
      </div>
    )
  }
)
Search.displayName = "Search"

type SearchResultProps = {
  collection: LaunchpadCollection
}

const SearchResult = ({ collection }: SearchResultProps) => {
  const { name, image } = collection

  return (
    <Link
      to={`launchpad/${collection.slug}`}
      className={cn(
        buttonVariants({
          variant: "ghost",
          className:
            "flex h-auto w-full flex-none items-center justify-start gap-3 rounded-none border-b p-3 backdrop-blur-0 last:border-b-0",
        })
      )}
    >
      <div className="h-10 w-10 flex-none overflow-hidden rounded-full">
        <Media media={image} size={MediaSize.Sm} />
      </div>

      <span className="overflow-hidden text-ellipsis text-sm font-medium">
        {name}
      </span>
    </Link>
  )
}

export { Search }
