import { profileService } from "@/services/profile-service"
import { zodResolver } from "@hookform/resolvers/zod"
import { MailIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import * as z from "zod"

import { Profile } from "@/types/profile"

import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Loading } from "@/components/ui/loading"

interface AddEmailFormProps {
  profile: Profile
  onSuccess: () => void
}

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
})

const AddEmailForm = ({ profile, onSuccess }: AddEmailFormProps) => {
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: z.infer<typeof formSchema>) => {
      return profileService.addEmail(profile.walletAddress, data)
    },
    onSuccess: () => {
      onSuccess()

      toast({
        title: "Verification email sent",
        description:
          "Check your inbox and click the verification link we've just sent to confirm your email.",
      })
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: error.message,
      })
    },
  })

  const handleAddEmailFormSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleAddEmailFormSubmit)}
        className="flex flex-col gap-5 md:flex-row "
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="relative flex w-full flex-col gap-2 space-y-0">
              <FormControl className="relative">
                <Input
                  type="email"
                  placeholder="Your email"
                  className="h-11 px-4 pl-11"
                  {...field}
                />
              </FormControl>

              <MailIcon className="absolute left-4 top-3.5 !mt-0 flex h-4 w-4 text-primary" />

              <FormMessage />
            </FormItem>
          )}
        />

        {isLoading ? (
          <Loading
            className="h-11 w-full flex-none rounded-full bg-frozen/60 md:w-32"
            _spinner={{
              className: "w-4 h-4",
            }}
          />
        ) : (
          <Button
            type="submit"
            size="lg"
            variant="gradient"
            className="w-full flex-none md:w-32"
          >
            Send
          </Button>
        )}
      </form>
    </Form>
  )
}

AddEmailForm.displayName = "AddEmailForm"

export { AddEmailForm }
