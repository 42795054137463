import ReactCountdown, {
  CountdownRenderProps,
  CountdownProps as ReactCountdownProps,
  zeroPad,
} from "react-countdown"

import { cn } from "@/utils/tw-utils"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

export interface CountdownProps extends ReactCountdownProps {}

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    return (
      <div className={cn("flex items-center")}>
        {days > 0 && <CountdownValue value={0} label="Days" />}
        <CountdownValue value={0} label="Hours" />
        <CountdownValue value={0} label="Minutes" />
        <CountdownValue value={0} label="Seconds" />
      </div>
    )
  } else {
    return (
      <div className={cn("flex items-center")}>
        {days > 0 && <CountdownValue value={days} label="Days" />}
        <CountdownValue value={hours} label="Hours" />
        <CountdownValue value={minutes} label="Minutes" />
        <CountdownValue value={seconds} label="Seconds" />
      </div>
    )
  }
}

const Countdown = ({ className, date, ...props }: CountdownProps) => {
  return (
    <ReactCountdown
      date={date}
      renderer={renderer}
      className={cn("", className)}
      {...props}
    />
  )
}

const CountdownValue = ({
  value,
  label,
  className,
}: {
  value: number
  label: string
  className?: string
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          className={cn(
            "flex w-8 items-center justify-center overflow-hidden text-ellipsis leading-5",
            className
          )}
        >
          {value < 100 ? zeroPad(value) : "??"}
        </span>
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  )
}

export { Countdown, CountdownValue }
