import React from "react"
import ReactDOM from "react-dom/client"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import "swiper/css/effect-creative"
import "@/styles/globals.css"
import "@/styles/overrides.css"

import { HelmetProvider } from "react-helmet-async"
import { QueryClient, QueryClientProvider } from "react-query"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"

import { initVenomConnect } from "@/config/venom-config"
import { ThemeProvider } from "@/providers/theme-provider"
import { VenomProvider } from "@/providers/venom-provider"
import { TooltipProvider } from "@/components/ui/tooltip"
import { LaunchPage } from "@/routes/launch-page"
import { LaunchpadPage } from "@/routes/launchpad-page"
import { NoMatchPage } from "@/routes/nomatch-page"
import { RewardsPage } from "@/routes/rewards-page"
import { RootLayout } from "@/routes/root-layout"
import { SupportPage } from "@/routes/support-page"

const queryClient = new QueryClient()

const helmetContext = {}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,

    children: [
      { path: "/", element: <Navigate to="/launchpad" /> },
      { path: "/launchpad", element: <LaunchpadPage /> },
      {
        path: "/launchpad/:slug",
        element: <LaunchPage />,
      },
      { path: "/support", element: <SupportPage /> },
      { path: "/rewards", element: <RewardsPage /> },
    ],
  },
  {
    path: "*",
    element: <NoMatchPage />,
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark">
      <TooltipProvider>
        <HelmetProvider context={helmetContext}>
          <QueryClientProvider client={queryClient}>
            <VenomProvider initVenomConnect={initVenomConnect}>
              <RouterProvider router={router} />
            </VenomProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </TooltipProvider>
    </ThemeProvider>
  </React.StrictMode>
)
