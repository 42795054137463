import { Helmet } from "react-helmet-async"

import { composePageTitle } from "@/utils/meta-utils"
import { useFaqQuery } from "@/hooks/use-faq-query"
import { Skeleton } from "@/components/ui/skeleton"
import { Faq } from "@/components/common/faq"

const metaTitle = composePageTitle("Support")
const metaDescription =
  "Your Partner in Navigating NFTs - Get assistance and guidance for your NFT journey. Connect with our dedicated support team for seamless experiences."

const SupportPage = () => {
  const { faq } = useFaqQuery()

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="container max-w-4xl px-3 py-12 md:px-6 md:py-16">
        {faq ? (
          <div className="flex flex-col gap-12">
            {faq.map((faq, index) => (
              <Faq key={index} faq={faq} />
            ))}
          </div>
        ) : (
          <SupportPageLoadingState />
        )}
      </div>
    </>
  )
}
SupportPage.displayName = "SupportPage"

const SupportPageLoadingState = () => {
  return (
    <div className="flex flex-col gap-12">
      <div className="flex w-full items-center gap-3 md:gap-6">
        <Skeleton className="h-12 w-12 flex-none rounded-full bg-frozen/60 md:h-14 md:w-14" />

        <div className="w-full">
          <Skeleton className="h-5 max-w-[14rem] rounded-sm md:h-7" />
          <Skeleton className="mt-2 h-5 max-w-[26rem] rounded-sm" />
        </div>
      </div>
      <div className="flex w-full items-center gap-3 md:gap-6">
        <Skeleton className="h-12 w-12 flex-none rounded-full bg-frozen/60 md:h-14 md:w-14" />

        <div className="w-full">
          <Skeleton className="h-5 max-w-[12rem] rounded-sm md:h-7" />
          <Skeleton className="mt-2 h-5 max-w-[26rem] rounded-sm" />
        </div>
      </div>
      <div className="flex w-full items-center gap-3 md:gap-6">
        <Skeleton className="h-12 w-12 flex-none rounded-full bg-frozen/60 md:h-14 md:w-14" />

        <div className="w-full">
          <Skeleton className="h-5 max-w-[10rem] rounded-sm md:h-7" />
          <Skeleton className="mt-2 h-5 max-w-[26rem] rounded-sm" />
        </div>
      </div>
    </div>
  )
}
SupportPageLoadingState.displayName = "SupportPageLoadingState"

export { SupportPage }
