import { useMemo, useState } from "react"
import {
  AccordionItemProps,
  AccordionSingleProps,
} from "@radix-ui/react-accordion"
// @ts-ignore
import { google } from "calendar-link"
import { CalendarIcon } from "lucide-react"

import {
  LaunchpadCollectionStage,
  LaunchpadCollectionStatus,
  LaunchpadCollectionWithDetails,
} from "@/types/launchpad-collection"

import { formatDateFull } from "@/utils/date-utils"
import { cn } from "@/utils/tw-utils"
import { useVenom } from "@/providers/venom-provider"
import { useLaunchStageStatus } from "@/hooks/use-launch-stage-status"
import { useLaunchStatus } from "@/hooks/use-launch-status"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Attribute } from "@/components/ui/attribute"
import { Badge } from "@/components/ui/badge"
import { Balance } from "@/components/ui/balance"
import { Button } from "@/components/ui/button"
import { Countdown } from "@/components/ui/countdown"
import { Diode } from "@/components/ui/diode"
import { Separator } from "@/components/ui/separator"
import { LaunchMintingForm } from "@/components/launchpad/launch-minting-form"

type LaunchMintingStagesProps = Omit<
  AccordionSingleProps,
  "children" | "type"
> & {
  collection: LaunchpadCollectionWithDetails
}

const LaunchMintingStages = ({
  collection,
  className,
  ...props
}: LaunchMintingStagesProps) => {
  const { stages } = collection

  const { currentStage, nextStage } = useLaunchStatus(collection)

  const [selectedStageIndex, setSelectedStageIndex] = useState<number>(
    currentStage?.index ?? nextStage?.index ?? collection.stages[0].index
  )

  return (
    <Accordion
      type="single"
      value={selectedStageIndex.toString()}
      onValueChange={(value) => {
        setSelectedStageIndex(parseInt(value, 10))
      }}
      collapsible
      className={cn(
        "border-b border-t md:overflow-hidden md:rounded-sm md:border",
        className
      )}
      {...props}
    >
      {stages.map((stage) => {
        return (
          <LaunchMintingStage
            key={stage.index}
            value={stage.index.toString()}
            stage={stage}
            collection={collection}
          />
        )
      })}
    </Accordion>
  )
}
LaunchMintingStages.displayName = "LaunchMintingStages"

type LaunchMintingStageProps = AccordionItemProps & {
  stage: LaunchpadCollectionStage
  collection: LaunchpadCollectionWithDetails
}

const LaunchMintingStage = ({
  stage,
  collection,
  className,
  ...props
}: LaunchMintingStageProps) => {
  const { connect, isConnected } = useVenom()
  const { status, endDate, startDate } = useLaunchStageStatus(stage)

  const isLive = status === LaunchpadCollectionStatus.Live
  const isEnded = status === LaunchpadCollectionStatus.Ended
  const isUpcoming = status === LaunchpadCollectionStatus.Upcoming

  const calendarLink = useMemo(() => {
    if (!isUpcoming) {
      return null
    }

    return google({
      title: `${collection.name} - ${stage.displayName}`,
      start: startDate,
      duration: [1, "hour"],
      description: collection.shortDescription ?? "",
    })
  }, [collection, stage, startDate, isUpcoming])

  const isPast = isEnded || isLive

  return (
    <AccordionItem className={cn("last:border-b-0", className)} {...props}>
      <AccordionTrigger className="px-3 md:px-4">
        <div className="mr-3 flex w-full flex-col items-start justify-start gap-3 md:flex-row md:items-center md:justify-between">
          <div className="flex items-center gap-3 pr-4 text-left font-semibold">
            {isLive ? <Diode /> : null}
            {isEnded ? <Diode state="off" color="input" /> : null}
            {isUpcoming ? <Diode state="off" color="input" /> : null}
            <span>{stage.displayName}</span>
          </div>

          <div className="hidden md:block">
            {isLive && (
              <div className="flex items-center gap-2 font-medium">
                <span className="whitespace-nowrap text-xs">ENDS IN</span>
                <Badge
                  variant="frozen"
                  className="flex-none px-1 leading-5 text-inherit transition-none"
                >
                  <Countdown date={endDate} />
                </Badge>
              </div>
            )}

            {isUpcoming && (
              <div className="e flex items-center gap-2 font-medium">
                <span className="whitespace-nowrap text-xs">STARTS IN</span>
                <Badge
                  variant="frozen"
                  className="px-1 leading-5 text-inherit transition-none"
                >
                  <Countdown date={startDate} />
                </Badge>
              </div>
            )}

            {isEnded && (
              <Badge
                variant="frozen"
                className="w-auto flex-none px-3 font-medium leading-5 text-inherit transition-none"
              >
                ENDED
              </Badge>
            )}
          </div>
        </div>
      </AccordionTrigger>

      <AccordionContent className="border-t bg-gradient-to-t from-accent/30 to-accent/0">
        <div className="px-3 py-6 md:px-6">
          <div className="grid grid-cols-2 gap-4">
            <Attribute name={isPast ? "Started at" : "Starts at"}>
              {formatDateFull(startDate)}
            </Attribute>
            <Attribute name={isEnded ? "Ended at" : "Ends at"}>
              {formatDateFull(endDate)}
            </Attribute>

            <Attribute name="Price">
              <Balance value={stage.price} />
            </Attribute>

            {stage.perWalletLimit ? (
              <Attribute name="Limit">
                {`${stage.perWalletLimit} per wallet`}
              </Attribute>
            ) : null}
          </div>

          {isLive && (
            <div className="flex w-full flex-col items-center">
              <Separator className="my-6" />

              {isConnected ? (
                <LaunchMintingForm
                  stage={stage}
                  collection={collection}
                  className="w-full"
                />
              ) : (
                <Button
                  variant="gradient"
                  size="lg"
                  className="w-full"
                  onClick={connect}
                >
                  Connect wallet
                </Button>
              )}
            </div>
          )}

          {isUpcoming && (
            <div>
              <Separator className="my-6" />

              <Button
                variant="outline"
                size="lg"
                className="relative w-full gap-2"
                asChild
              >
                <a
                  href={calendarLink ?? undefined}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CalendarIcon className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2" />
                  Add to calendar
                </a>
              </Button>
            </div>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export { LaunchMintingStages }
