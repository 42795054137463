import { Outlet } from "react-router-dom"

import { LayoutProvider } from "@/providers/layout-provider"
import { ScrollToTop } from "@/components/ui/scroll-to-top"
import { Separator } from "@/components/ui/separator"
import { Toaster } from "@/components/ui/toaster"
import { Drawer } from "@/components/layout/drawer"
import { Footer } from "@/components/layout/footer"
import { Header } from "@/components/layout/header"

const RootLayout = () => {
  return (
    <LayoutProvider>
      <Header />
      <Drawer />

      <main>
        <Outlet />
      </main>

      <Separator className="mt-16" />

      <ScrollToTop />

      <Footer />
      <Toaster />
    </LayoutProvider>
  )
}
RootLayout.displayName = "RootLayout"

export { RootLayout }
