import { useCallback, useState } from "react"
import {
  Autoplay,
  EffectCreative,
  Navigation,
  Pagination,
} from "swiper/modules"
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper/types"

import { LaunchpadCollection } from "@/types/launchpad-collection"
import { MediaSize } from "@/types/media"

import { cn } from "@/utils/tw-utils"
import { Media } from "@/components/ui/media"
import { FeaturedCollectionBanner } from "@/components/launchpad/launch-banner"

const launchesSwiperStyle = "-mx-3 rounded-md md:mx-0"

type LaunchesSwiperProps = SwiperProps & {
  collections: LaunchpadCollection[]
}

const LaunchesSwiper = ({
  collections,
  className,
  ...props
}: LaunchesSwiperProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0)

  const currentCollection =
    activeIndex !== null ? collections?.[activeIndex] ?? null : null

  const handleSwipeChange = useCallback(
    (swiper: SwiperType) => {
      setActiveIndex(swiper.realIndex)
    },
    [setActiveIndex]
  )

  return (
    <>
      {currentCollection && currentCollection.banner ? (
        <div className="absolute left-1/2 top-8 -z-10 w-full max-w-screen-2xl -translate-x-1/2 animate-breath opacity-20 blur-3xl">
          <Media
            media={currentCollection.banner}
            size={MediaSize.Lg}
            skeleton={false}
          />
        </div>
      ) : null}

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[Navigation, Pagination, Autoplay, EffectCreative]}
        navigation
        pagination={{ clickable: false }}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        }}
        loop
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        effect="creative"
        onSlideChange={handleSwipeChange}
        className={cn(launchesSwiperStyle, className)}
        {...props}
      >
        {collections?.map((collection) => {
          return (
            <SwiperSlide key={collection.id}>
              <FeaturedCollectionBanner
                collection={collection}
                className="select-none"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}
LaunchesSwiper.displayName = "LaunchesSwiper"

export { LaunchesSwiper, launchesSwiperStyle }
