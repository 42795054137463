import { ComponentPropsWithoutRef } from "react"

import { formatLevel } from "@/utils/rewards-utils"
import { cn } from "@/utils/tw-utils"
import { Badge } from "@/components/ui/badge"

interface LevelBadgeProps extends ComponentPropsWithoutRef<typeof Badge> {
  rewards: number
}

export const LevelBadge = ({ rewards, className }: LevelBadgeProps) => {
  return (
    <Badge
      variant="frozen"
      className={cn("text-xs font-medium leading-snug text-primary", className)}
    >
      LEVEL {formatLevel(rewards)}
    </Badge>
  )
}
