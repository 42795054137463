import { Link } from "react-router-dom"

import { cn } from "@/utils/tw-utils"
import { useLayout } from "@/providers/layout-provider"
import { Button } from "@/components/ui/button"
import { SocialLinks } from "@/components/common/social-links"
import { Logo } from "@/components/icons/logo"
import VenomPoweredBadge from "@/components/icons/venom-powered-badge"

const Footer = () => {
  const { isSearchActive } = useLayout()

  return (
    <footer className="container relative z-10 flex w-full px-3 py-16 md:px-6">
      <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4 lg:gap-6">
        <div className="flex flex-col items-center justify-between md:items-start">
          <Link
            to="/launchpad"
            className={cn(
              "flex h-6 w-[10rem] flex-none items-center rounded-md outline-none",
              {
                hidden: isSearchActive,
              }
            )}
          >
            <span className="sr-only">Home</span>
            <Logo className="w-full text-primary" />
          </Link>

          <span className="mt-4 block text-xs text-muted-foreground lg:mt-auto">
            ©2023 Dynamic Labs
          </span>
        </div>

        <div className="flex flex-col items-center justify-between md:items-start">
          <Button variant="link" className="h-auto p-0" asChild>
            <a href="mailto:info@gateway.network">info@gateway.network</a>
          </Button>

          <SocialLinks className="mt-4" />
        </div>

        <nav>
          <ul className="flex flex-col items-center justify-between gap-4 md:items-start md:gap-6">
            <li>
              <Button variant="link" className="h-auto p-0" asChild>
                <Link to="/launchpad">Launchpad</Link>
              </Button>
            </li>
            <li>
              <Button variant="link" className="h-auto p-0" asChild>
                <Link to="/support">Support</Link>
              </Button>
            </li>
            <li>
              <Button variant="link" className="h-auto p-0" asChild>
                <Link to="/rewards">Rewards</Link>
              </Button>
            </li>
          </ul>
        </nav>

        <div className="flex flex-col items-center justify-between md:items-start">
          <a
            href="https://github.com/venom-blockchain/developer-program"
            className="block w-36 outline-none md:w-40"
          >
            <VenomPoweredBadge />
          </a>
        </div>
      </div>
    </footer>
  )
}

Footer.displayName = "Footer"

export { Footer }
