import { Profile } from "@/types/profile"

export const profileMock: Profile = {
  id: "1",
  rewards: 250,
  walletAddress: "0:000",
  completedTasks: ["1"],

  updatedAt: "2021-08-31T12:00:00.000Z",
  createdAt: "2021-08-31T12:00:00.000Z",
}
