export interface Faq {
  title: string
  description: string
  category: FaqCategory
  entries: FaqEntry[]
}

export enum FaqCategory {
  General = "general",
  Profile = "profile",
  Help = "help",
  Rewards = "rewards",
  Safety = "safety",
  Venom = "venom",
  Launchpad = "launchpad",
}

export interface FaqEntry {
  question: string
  answer: string
}
