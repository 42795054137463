import { apiService } from "@/services/api-service"

import { Faq } from "@/types/faq"

import { wait } from "@/utils/wait"
import { appConfig } from "@/config/app-config"
import { faqMock } from "@/config/mocks/faq-mocks"

const getFaq = async (): Promise<Faq[]> => {
  if (appConfig.useMocks) {
    await wait(200)
    return faqMock
  }

  const res = await apiService.get<Faq[]>("/faq")
  return res.data
}

export const faqService = {
  getFaq,
}
