import { TwitterIcon } from "lucide-react"

import { cn } from "@/utils/tw-utils"
import { Button } from "@/components/ui/button"
import DiscordIcon from "@/components/icons/discord-icon"

interface SocialLinksProps extends React.ComponentPropsWithRef<"div"> {}

const SocialLinks = ({ className }: SocialLinksProps) => {
  return (
    <ul className={cn("flex gap-4", className)}>
      <li>
        <Button size="icon" className="h-11 w-11" variant="outline" asChild>
          <a
            href="https://twitter.com/gatewaynetwork"
            target="_blank"
            rel="noreferrer"
            className="transition-colors"
          >
            <TwitterIcon className="h-5 w-5" />
          </a>
        </Button>
      </li>
      <li>
        <Button size="icon" className="h-11 w-11" variant="outline" asChild>
          <a
            href="https://discord.com/gatewaynetwork"
            target="_blank"
            rel="noreferrer"
            className="transition-colors"
          >
            <DiscordIcon className="h-5 w-5" />
          </a>
        </Button>
      </li>
    </ul>
  )
}

SocialLinks.displayName = "SocialLinks"

export { SocialLinks }
