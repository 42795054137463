import BigNumber from "bignumber.js"
import { Address, ProviderRpcClient } from "everscale-inpage-provider"

import { Account } from "@/types/venom"

export function shortenAddress(address: Address | string, length = 6) {
  const addressString = String(address)

  return `${addressString.slice(0, length + 2)}...${addressString.slice(
    addressString.length - 3
  )}`
}

export function formatBalance(balance: string | number) {
  return new BigNumber(formatNano(balance)).toFixed(2)
}

export function formatNano(
  value: string | number,
  decimal: number = 9
): string {
  const bnValue = new BigNumber(value)
  return bnValue.dividedBy(10 ** decimal).toString()
}

export function toNano(value: string | number): string {
  const bnValue = new BigNumber(value)
  return bnValue.multipliedBy(10 ** 9).toString()
}

export async function getProviderAccount(
  provider: ProviderRpcClient
): Promise<Account | undefined> {
  const providerState = await provider?.getProviderState?.()
  return providerState?.permissions.accountInteraction
}

export function getAddress(address: Address | string) {
  return address instanceof Address ? address : new Address(address)
}
