import { ExternalLink } from "@/types/external-link"
import { FaqEntry } from "@/types/faq"
import { Media } from "@/types/media"
import { RoadmapEntry } from "@/types/roadmap-entry"
import { TeamMember } from "@/types/team-member"

export interface LaunchpadCollection {
  id: string
  name: string
  shortDescription?: string
  slug: string
  price: number
  maxSupplySum?: number

  image: Media
  banner?: Media

  stages: LaunchpadCollectionStage[]

  isPublished: boolean
  isFeatured: boolean

  blockchain: LaunchpadCollectionBlockchain

  tvm: {
    contractAddress: string
    contractType: LaunchpadCollectionContractType
  }

  createdAt: string
  updatedAt: string
}

export interface LaunchpadCollectionWithDetails extends LaunchpadCollection {
  description?: string
  richDescription: string

  media: Media[]
  links: ExternalLink[]

  roadmap?: RoadmapEntry[]
  faq?: FaqEntry[]
  team?: TeamMember[]
}

export interface LaunchpadCollectionStage {
  type: LaunchpadCollectionStageType
  price: number
  perWalletLimit?: number
  maxSupply?: number
  startTime: number
  endTime: number

  index: number
  displayName: string
  allowlistUrl?: string
}

export enum LaunchpadCollectionStageType {
  Allowlist = "allowlist",
  Public = "public",
}

export enum LaunchpadCollectionContractType {
  Tip4 = "TIP-4",
}

export enum LaunchpadCollectionBlockchain {
  Venom = "venom",
}

export enum LaunchpadCollectionStatus {
  Upcoming = "upcoming",
  Live = "live",
  Ended = "ended",
  SoldOut = "sold-out",
  Paused = "paused",
}
