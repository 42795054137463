import { LaunchpadCollection } from "@/types/launchpad-collection"

import { cn } from "@/utils/tw-utils"
import { EmptyState } from "@/components/ui/empty-state"
import { LaunchCard } from "@/components/launchpad/launch-card"

type LaunchesGridProps = React.ComponentPropsWithoutRef<"div"> & {
  collections: LaunchpadCollection[]
  emptyStateText?: string
}

const LaunchesGrid = ({
  collections,
  emptyStateText = "No collections found...",
  className,
  ...props
}: LaunchesGridProps) => {
  const hasCollections = collections.length > 0

  return hasCollections ? (
    <div
      className={cn(
        "grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 lg:gap-6",
        className
      )}
      {...props}
    >
      {collections.map((collection) => (
        <LaunchCard key={collection.id} collection={collection} />
      ))}
    </div>
  ) : (
    <EmptyState
      className="py-20"
      _icon={{
        className: "text-foreground",
      }}
      text={emptyStateText}
    />
  )
}
LaunchesGrid.displayName = "LaunchesGrid"

export { LaunchesGrid }
