import { useEffect, useRef, useState } from "react"

import { cn } from "@/utils/tw-utils"
import { Skeleton } from "@/components/ui/skeleton"

export interface ImageProps
  extends Omit<React.ComponentPropsWithRef<"img">, "onLoad"> {
  onLoad?: () => void
  skeleton?: boolean
}

const Image = ({
  className,
  skeleton = true,
  onLoad,
  ...props
}: ImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isLoaded) {
      onLoad?.()
    }
  }, [isLoaded, onLoad])

  useEffect(() => {
    if (imageRef?.current?.complete) {
      setIsLoaded(true)
      onLoad?.()
    }
  }, [onLoad, imageRef])

  return (
    <div
      className={cn("relative block", className)}
      data-state={isLoaded ? "loaded" : undefined}
    >
      {!isLoaded && skeleton && (
        <Skeleton className="absolute left-0 top-0 h-full w-full" />
      )}

      <img
        ref={imageRef}
        onLoad={() => {
          setIsLoaded(true)
        }}
        loading="lazy"
        className={cn(
          "h-full w-full object-cover opacity-0 transition-opacity duration-500",
          {
            "opacity-100": isLoaded,
          }
        )}
        {...props}
      />
    </div>
  )
}
Image.displayName = "Image"

export { Image }
