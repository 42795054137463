import { useMemo } from "react"
import { useQuery } from "react-query"

import { formatNano } from "@/utils/venom-utils"
import { useVenom } from "@/providers/venom-provider"

export const useBalance = () => {
  const { account, venomProvider } = useVenom()

  const queryResult = useQuery(
    ["balance", String(account?.address)],
    async () => {
      if (!venomProvider || !account) {
        return undefined
      }

      return venomProvider.getBalance(account.address)
    },
    {
      enabled: !!venomProvider && !!account,
    }
  )

  return useMemo(() => {
    return {
      balance: queryResult.data,
      formattedBalance: queryResult.data
        ? formatNano(queryResult.data)
        : undefined,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
    }
  }, [queryResult.data, queryResult.error, queryResult.isLoading])
}
