import { GemIcon, InfoIcon, RocketIcon } from "lucide-react"
import { Link } from "react-router-dom"

import { cn } from "@/utils/tw-utils"
import { useLayout } from "@/providers/layout-provider"
import { Menu, MenuLink } from "@/components/ui/menu"
import { Sheet, SheetContent, SheetHeader } from "@/components/ui/sheet"
import { Logo } from "@/components/icons/logo"
import { Connect } from "@/components/layout/connect"

const Drawer = () => {
  const { isDrawerOpen, toggleDrawer } = useLayout()

  return (
    <Sheet open={isDrawerOpen} onOpenChange={toggleDrawer}>
      <SheetContent side="left" className="w-[20rem] overflow-hidden p-0">
        <SheetHeader className="flex h-[14rem] items-center justify-center px-2">
          <Link
            to="/launchpad"
            className={cn(
              "flex h-10 w-[10rem] items-center rounded-md bg-background text-primary outline-none ring-offset-background focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0"
            )}
          >
            <span className="sr-only">Home</span>
            <Logo className="w-full" />
          </Link>
        </SheetHeader>

        <div className="flex h-16 items-center justify-center px-6">
          <Connect
            className="h-full w-full"
            _profileButton={{
              className: "w-full justify-start px-3 border",
            }}
            _popoverContent={{
              className: "w-[16.9375rem]",
            }}
            _connectButtonWrapper={{
              className:
                "flex h-full items-center justify-center rounded-md border border-border px-3 w-full",
            }}
          />
        </div>

        <Menu className="flex-col items-start gap-2 px-6 py-8">
          <MenuLink
            to="/rewards"
            title="Rewards"
            className="h-12 w-full justify-start gap-2 [&_[data-pulse]]:bg-secondary [&_svg]:text-secondary"
            icon={GemIcon}
            pulse
          />

          <MenuLink
            to="/launchpad"
            title="Launchpad"
            icon={RocketIcon}
            className="h-12 w-full justify-start gap-2"
          />

          <MenuLink
            to="/support"
            title="Support"
            className="h-12 w-full justify-start gap-2"
            icon={InfoIcon}
          />
        </Menu>
      </SheetContent>
    </Sheet>
  )
}

Drawer.displayName = "Drawer"

export { Drawer }
