import { ExternalLinkType } from "@/types/external-link"
import {
  LaunchpadCollection,
  LaunchpadCollectionBlockchain,
  LaunchpadCollectionContractType,
  LaunchpadCollectionStageType,
  LaunchpadCollectionWithDetails,
} from "@/types/launchpad-collection"

const getFutureDateInIso = (days: number) => {
  const now = new Date()
  const futureDate = new Date()
  futureDate.setDate(now.getDate() + days)
  return futureDate.getTime()
}

export const launchpadCollectionsMock: LaunchpadCollection[] = [
  {
    id: "1",
    slug: "deforce-degen-pirates-genesis",
    name: "DeForce Degen Pirates Genesis",
    shortDescription:
      "Join KYDS, a web3 powerhouse creating top-tier art, tools, and retro games. Own a KYDS collectible for BSMNT access – where nostalgia meets innovation!",
    price: 49000000000,
    maxSupplySum: 3500,

    isPublished: true,
    isFeatured: true,

    blockchain: LaunchpadCollectionBlockchain.Venom,
    tvm: {
      contractAddress:
        "0:ffaa91a6762f494d5373b5d6e001d6d0fe7878dc0eb7ad81c7bcdf8412edf888",
      contractType: LaunchpadCollectionContractType.Tip4,
    },

    stages: [
      {
        index: 0,
        displayName: "Public stage",
        price: 39000000000,
        perWalletLimit: 10,
        maxSupply: 3500,
        startTime: getFutureDateInIso(0),
        endTime: getFutureDateInIso(1),
        type: LaunchpadCollectionStageType.Public,
      },
    ],

    banner: {
      alt: "DeForce Degen Pirates Genesis",
      type: "image",
      sizes: {
        original:
          "https://nftstorage.link/ipfs/bafybeihl3gszmop2jrq7yt6yfhewaaawimdtzmlwvs6ggmqybjyfyr5lwm",
        sm: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://nftstorage.link/ipfs/bafybeihl3gszmop2jrq7yt6yfhewaaawimdtzmlwvs6ggmqybjyfyr5lwm",
        md: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://nftstorage.link/ipfs/bafybeihl3gszmop2jrq7yt6yfhewaaawimdtzmlwvs6ggmqybjyfyr5lwm",
        lg: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://nftstorage.link/ipfs/bafybeihl3gszmop2jrq7yt6yfhewaaawimdtzmlwvs6ggmqybjyfyr5lwm",
      },
    },

    image: {
      alt: "DeForce Degen Pirates Genesis",
      type: "image",
      sizes: {
        original:
          "https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
        sm: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
        md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
        lg: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
      },
    },

    updatedAt: "2023-08-22T18:24:30.510Z",
    createdAt: "2023-08-22T18:24:30.510Z",
  },
  {
    id: "2",
    slug: "kyds",
    name: "KYDS",
    shortDescription:
      "Join KYDS, a web3 powerhouse creating top-tier art, tools, and retro games. Own a KYDS collectible for BSMNT access – where nostalgia meets innovation",
    price: 37500000000,
    maxSupplySum: 3750,

    isPublished: true,
    isFeatured: true,

    blockchain: LaunchpadCollectionBlockchain.Venom,
    tvm: {
      contractAddress:
        "0:ffaa91a6762f494d5373b5d6e001d6d0fe7878dc0eb7ad81c7bcdf8412edf888",
      contractType: LaunchpadCollectionContractType.Tip4,
    },

    stages: [
      {
        index: 0,
        displayName: "Public stage",
        price: 39000000000,
        perWalletLimit: 10,
        maxSupply: 3500,
        startTime: getFutureDateInIso(1),
        endTime: getFutureDateInIso(2),
        type: LaunchpadCollectionStageType.Public,
      },
    ],

    banner: {
      alt: "KYDS",
      type: "image",
      sizes: {
        original:
          "https://bafybeibwaj6fvatkbuapz5ykgpdur3ezfcmjip6sne4hhx33kvx6lncyim.ipfs.nftstorage.link/",
        sm: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeibwaj6fvatkbuapz5ykgpdur3ezfcmjip6sne4hhx33kvx6lncyim.ipfs.nftstorage.link/",
        md: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeibwaj6fvatkbuapz5ykgpdur3ezfcmjip6sne4hhx33kvx6lncyim.ipfs.nftstorage.link/",
        lg: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeibwaj6fvatkbuapz5ykgpdur3ezfcmjip6sne4hhx33kvx6lncyim.ipfs.nftstorage.link/",
      },
    },

    image: {
      alt: "KYDS",
      type: "image",
      sizes: {
        original:
          "https://bafkreihmcu7wczdhaew2riipniiozj54wlwymuy2cdf4ir5x3qkmh7umci.ipfs.nftstorage.link/",
        sm: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://bafkreihmcu7wczdhaew2riipniiozj54wlwymuy2cdf4ir5x3qkmh7umci.ipfs.nftstorage.link/",
        md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://bafkreihmcu7wczdhaew2riipniiozj54wlwymuy2cdf4ir5x3qkmh7umci.ipfs.nftstorage.link/",
        lg: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://bafkreihmcu7wczdhaew2riipniiozj54wlwymuy2cdf4ir5x3qkmh7umci.ipfs.nftstorage.link/",
      },
    },

    updatedAt: "2023-08-22T18:24:30.510Z",
    createdAt: "2023-08-22T18:24:30.510Z",
  },
  {
    id: "3",
    slug: "blocklords-bisping-hero-chest",
    name: "BLOCKLORDS BISPING HERO CHEST",
    shortDescription:
      "BLOCKLORDS is a player-driven grand strategy game by MetaKing Studios. Rule, conquer, and grow with your medieval Hero.",
    price: 37500000000,
    maxSupplySum: 3750,

    stages: [
      {
        index: 0,
        displayName: "Public stage",
        price: 39000000000,
        perWalletLimit: 10,
        maxSupply: 3500,
        startTime: getFutureDateInIso(2),
        endTime: getFutureDateInIso(3),
        type: LaunchpadCollectionStageType.Public,
      },
    ],

    isPublished: true,
    isFeatured: true,

    blockchain: LaunchpadCollectionBlockchain.Venom,
    tvm: {
      contractAddress:
        "0:ffaa91a6762f494d5373b5d6e001d6d0fe7878dc0eb7ad81c7bcdf8412edf888",
      contractType: LaunchpadCollectionContractType.Tip4,
    },

    banner: {
      alt: "BLOCKLORDS BISPING HERO CHEST",
      type: "image",
      sizes: {
        original:
          "https://bafybeifo64tz6tiwo2shplibfpcupez5c4dmmx4zsqjxgc3q242rblqtua.ipfs.nftstorage.link/",
        sm: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeifo64tz6tiwo2shplibfpcupez5c4dmmx4zsqjxgc3q242rblqtua.ipfs.nftstorage.link/",
        md: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeifo64tz6tiwo2shplibfpcupez5c4dmmx4zsqjxgc3q242rblqtua.ipfs.nftstorage.link/",
        lg: "https://img-cdn.magiceden.dev/rs:fit:3440:3440:0:0/plain/https://bafybeifo64tz6tiwo2shplibfpcupez5c4dmmx4zsqjxgc3q242rblqtua.ipfs.nftstorage.link/",
      },
    },

    image: {
      alt: "BLOCKLORDS BISPING HERO CHEST",
      type: "video",
      sizes: {
        original:
          "https://bafybeicdttgker2bhswo7l55fgyqinvadpbtwokgf75wslek57jlgxvvwe.ipfs.nftstorage.link/?=mp4",
        sm: "https://bafybeicdttgker2bhswo7l55fgyqinvadpbtwokgf75wslek57jlgxvvwe.ipfs.nftstorage.link/?=mp4",
        md: "https://bafybeicdttgker2bhswo7l55fgyqinvadpbtwokgf75wslek57jlgxvvwe.ipfs.nftstorage.link/?=mp4",
        lg: "https://bafybeicdttgker2bhswo7l55fgyqinvadpbtwokgf75wslek57jlgxvvwe.ipfs.nftstorage.link/?=mp4",
      },
    },

    updatedAt: "2023-08-22T18:24:30.510Z",
    createdAt: "2023-08-22T18:24:30.510Z",
  },
]

export const launchpadCollectionWithDetailsMock: LaunchpadCollectionWithDetails =
  {
    id: "1",
    slug: "deforce-degen-pirates-genesis",
    name: "DeForce Degen Pirates Genesis",
    price: 10000000000,

    isPublished: true,
    isFeatured: true,

    blockchain: LaunchpadCollectionBlockchain.Venom,
    tvm: {
      contractAddress:
        "0:ffaa91a6762f494d5373b5d6e001d6d0fe7878dc0eb7ad81c7bcdf8412edf888",
      contractType: LaunchpadCollectionContractType.Tip4,
    },

    image: {
      alt: "",
      sizes: {
        original:
          "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/image.mp4",
        sm: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/image.mp4",
        md: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/image.mp4",
        lg: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/image.mp4",
      },
      type: "video",
    },
    banner: {
      alt: "",
      sizes: {
        original:
          "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/banner2_org.webp",
        sm: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/banner2_sm.webp",
        md: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/banner2_md.webp",
        lg: "https://venomgateway-dev.fra1.digitaloceanspaces.com/gateway-mint-pass/banner2_lg.webp",
      },
      type: "image",
    },

    updatedAt: "2023-08-22T18:24:30.510Z",
    createdAt: "2023-08-22T18:24:30.510Z",

    shortDescription:
      "Join KYDS, a web3 powerhouse creating top-tier art, tools, and retro games. Own a KYDS collectible for BSMNT access – where nostalgia meets innovation!",
    description:
      "<p>DegenPirates is a game lab that produces on-chain Web3 games with instant rewards. We are driven by a mission to ignite excitement in web3 communities across chains. We believe in providing an easy-to-join and fun-to-play environment, where players can immerse themselves in the true spirit of the Web3 movement.</p> <p>Our first initiative to achieve this is to produce a series of on-chain indie web3 games with instant rewards. While these may not have the fanciness of mid-core or triple-A titles, their smaller scope allows for quicker development and publishing. As a result, we are able to deliver entertainment value to web3 users at a faster pace, ensuring timely and enjoyable experiences.</p>",
    richDescription: `<div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-10 xl:gap-16">
  <div>
    <p>
      DegenPirates is a game lab that produces on-chain Web3 games
      with instant rewards. We are driven by a mission to ignite
      excitement in web3 communities across chains. We believe in
      providing an easy-to-join and fun-to-play environment, where
      players can immerse themselves in the true spirit of the
      Web3 movement.
    </p>

    <p>
      Our first initiative to achieve this is to produce a series
      of on-chain indie web3 games with instant rewards. While
      these may not have the fanciness of mid-core or triple-A
      titles, their smaller scope allows for quicker development
      and publishing. As a result, we are able to deliver
      entertainment value to web3 users at a faster pace, ensuring
      timely and enjoyable experiences.
    </p>

    <p>
      "The long-term goal of the project is to build an open
      entertainment platform where indie web3 developers can
      publish their games and other forms of entertainment as
      well, not limited to game only".
    </p>

    <p>
      Users will utilize DeForce✦ NFT as a pass to access special
      events, exclusive perks for holders, and all the games on
      our platform, including entertainment products produced by
      DegenPirates Labs and our partners.
    </p>

    <p>
      DeForce✦ is the genesis PFP collection of DegenPirates Labs
      on Polygon, all future projects from DegenPirates Labs will
      contribute value to DeForce✦ collection in one way or
      another.
    </p>

    <h4>Utility</h4>

    <p>
      The DeForce✦ features unique PFPs that symbolize your
      commitment to the crew and unlock exclusive benefits within
      the DegenPirates universe.
    </p>

    <ul>
      <li>
        DeForce✦ is the genesis collection so all of the future
        projects from DegenPirates Labs will contribute value to
        this NFT collection in one way or another.
      </li>
      <li>
        Holders can stake their DeForce✦ PFP NFTs to earn $DPG
        (future DegenPirates Gold token)
      </li>
      <li>
        Holders get access to exclusive events on degenpirates.gg
      </li>
      <li>Holders get airdrop in-game items</li>
    </ul>
  </div>

  <div>
    <img
      src="https://uploads-ssl.webflow.com/64632f1244ea290c3b9e8951/64632f1344ea290c3b9e906f_spaceship%203.webp"
      loading="lazy"
      alt="deforce"
      class="scale-x-[-1]"
    />
  </div>
</div>`,

    stages: [
      {
        index: 1,
        displayName: "Allowlist",
        price: 1500000000,
        perWalletLimit: 10,
        maxSupply: 500,
        startTime: 1694169006,
        endTime: 1694428205,
        type: LaunchpadCollectionStageType.Allowlist,
        allowlistUrl: "",
      },
      {
        index: 2,
        displayName: "Pre-sale stage",
        price: 2000000000,
        perWalletLimit: 10,
        maxSupply: 2000,
        startTime: 1694428206,
        endTime: 1694514605,
        type: LaunchpadCollectionStageType.Public,
      },
      {
        index: 5,
        displayName: "Public sale stage",
        price: 0,
        perWalletLimit: 10,
        maxSupply: 50000,
        startTime: 1694687406,
        endTime: 1697279405,
        type: LaunchpadCollectionStageType.Public,
      },
    ],

    links: [
      {
        title: "Website",
        type: ExternalLinkType.Website,
        url: "https://www.degenpirates.gg/",
      },
      {
        title: "Twitter",
        type: ExternalLinkType.Twitter,
        url: "https://twitter.com/degenpirates",
      },
      {
        title: "Discord",
        type: ExternalLinkType.Discord,
        url: "https://discord.gg/degenpirates",
      },
    ],

    media: [
      {
        alt: "",
        type: "image",
        sizes: {
          // original: 3440x3440
          // lg: 1140x1140
          // md: 640x640
          // sm: 360x360
          original:
            "https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
          sm: "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
          md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
          lg: "https://img-cdn.magiceden.dev/rs:fill:1434:1434:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
        },
      },
      {
        alt: "",
        type: "image",
        sizes: {
          original:
            "https://nftstorage.link/ipfs/bafybeicurke23ei7f7ieg6zxaxf6as2tuxg3dqtksxfz7mdgjwc2mocl2u",
          sm: "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeicurke23ei7f7ieg6zxaxf6as2tuxg3dqtksxfz7mdgjwc2mocl2u",
          md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeicurke23ei7f7ieg6zxaxf6as2tuxg3dqtksxfz7mdgjwc2mocl2u",
          lg: "https://img-cdn.magiceden.dev/rs:fill:1434:1434:0:0/plain/https://nftstorage.link/ipfs/bafybeicurke23ei7f7ieg6zxaxf6as2tuxg3dqtksxfz7mdgjwc2mocl2u",
        },
      },
      {
        alt: "",
        type: "image",
        sizes: {
          original:
            "https://nftstorage.link/ipfs/bafybeidaltovw4p22igkezeigidwafmujzo2gdnf7wzzjjcczkhr4j62za",
          sm: "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeidaltovw4p22igkezeigidwafmujzo2gdnf7wzzjjcczkhr4j62za",
          md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeidaltovw4p22igkezeigidwafmujzo2gdnf7wzzjjcczkhr4j62za",
          lg: "https://img-cdn.magiceden.dev/rs:fill:1434:1434:0:0/plain/https://nftstorage.link/ipfs/bafybeidaltovw4p22igkezeigidwafmujzo2gdnf7wzzjjcczkhr4j62za",
        },
      },
      {
        alt: "",
        type: "image",
        sizes: {
          original:
            "https://nftstorage.link/ipfs/bafybeie2zngwrhwlcao2eqb6lgeb74o6wkgmdpoam7bn3zxhzewu5m3gnu",
          sm: "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeie2zngwrhwlcao2eqb6lgeb74o6wkgmdpoam7bn3zxhzewu5m3gnu",
          md: "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeie2zngwrhwlcao2eqb6lgeb74o6wkgmdpoam7bn3zxhzewu5m3gnu",
          lg: "https://img-cdn.magiceden.dev/rs:fill:1434:1434:0:0/plain/https://nftstorage.link/ipfs/bafybeie2zngwrhwlcao2eqb6lgeb74o6wkgmdpoam7bn3zxhzewu5m3gnu",
        },
      },
    ],

    faq: [
      {
        question: "What is DegenPirates?",
        answer:
          "DegenPirates is a game lab dedicated to creating on-chain Web3 games with instant rewards. They aim to bring excitement to Web3 communities by offering a fun and accessible gaming environment.",
      },
      {
        question: "What is the primary goal of DegenPirates' first initiative?",
        answer:
          "The primary goal of their first initiative is to develop a series of on-chain indie web3 games with instant rewards. These games might not be as complex as high-end titles, but their smaller scope enables faster development and publishing to deliver timely entertainment experiences.",
      },
      {
        question: "What is the long-term vision of DegenPirates?",
        answer:
          "The long-term vision is to establish an open entertainment platform where indie web3 developers can publish various forms of entertainment beyond games. The platform aims to support a diverse range of content, not limited to gaming.",
      },
      {
        question: "How does the DeForce✦ NFT benefit users?",
        answer:
          "Users can use the DeForce✦ NFT to access special events, exclusive perks, and all games on the DegenPirates platform, including content created by DegenPirates Labs and their partners.",
      },
      {
        question: "What is the utility of the DeForce✦ NFT?",
        answer:
          "The DeForce✦ NFT features unique PFPs that represent a user's commitment to the DegenPirates crew and provide access to exclusive benefits within the DegenPirates universe. Holders can also stake their DeForce✦ PFP NFTs to earn the future DegenPirates Gold token ($DPG), gain access to exclusive events, and receive in-game item airdrops.",
      },
    ],

    roadmap: [
      {
        title: "Stage 1",
        subtitle: "May 2023",
        description: "Launch degenpirates.gg mainnet on Polygon",
        // size: 256x256
        image:
          "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeidwmlr62jasrgilzdlwhdarmtw2v5gvpl42axzq2pyr34rzx3jgty",
      },
      {
        title: "Stage 2",
        subtitle: "June 2023",
        image:
          "https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://nftstorage.link/ipfs/bafybeicurke23ei7f7ieg6zxaxf6as2tuxg3dqtksxfz7mdgjwc2mocl2u",
        description: "First free-mint of Starbound Rocket Crates",
      },
      {
        title: "Q3 2023",
        image:
          "https://img-cdn.magiceden.dev/rs:fill:359:359:0:0/plain/https://nftstorage.link/ipfs/bafybeidaltovw4p22igkezeigidwafmujzo2gdnf7wzzjjcczkhr4j62za",
        description:
          "DeForce✦ PFP collection mint, the genesis collection of DegenPirates Labs",
      },
      {
        title: "Q4 2023",
        description: "$DPG token launch",
      },
      {
        title: "Q3 2024",
        description: "New games: DegenDice, a skill-based P2E game",
      },
    ],

    team: [
      {
        name: "0xGarp",
        title: "Founder of DegenPirates Labs",
        description:
          "Excellent CEO and visionary with years of experience in building Web3 Game, NFT, and DeFi products",
        // size: 256x256
        avatar:
          "https://bafybeifyrkikpa6ahxr4pscjpnz4deie2uo7zax3phkkuue5sa37hga6nq.ipfs.nftstorage.link/",
        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xGarp_gg",
          },
        ],
      },
      {
        name: "0xLaw",
        title: "Co-founder & CMO",
        description:
          "A marketing expert with over 3 years in web3 growing Web3 games",
        avatar:
          "https://bafybeiaudzzwctrah37t34cj6vxiq2hcbgxijjocjpm62ukvp4zkfdxgrq.ipfs.nftstorage.link/",
        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xLaw_",
          },
        ],
      },
      {
        name: "Ace",
        title: "Game Designer",
        description:
          "Technically the father of degenpirates.gg. He designs and builds anything we need from game design, concept art, and user experience.",
        avatar:
          "https://bafybeifioqxtxdvzx4ben2mu3ujijtnk5rkgraudevqrovehd54sr6x464.ipfs.nftstorage.link/",
        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xAce_degen",
          },
        ],
      },
      {
        name: "Shanks",
        title: "Advisor",
        description:
          "Shanks is our financial expert and a diverse entrepreneur with nearly 10 years of doing business.",
        avatar:
          "https://bafybeigpgcokxnr7uf2ylbuodgaqga2s3tsq3j4ks6cwb4glypyrq6cmfa.ipfs.nftstorage.link/",
        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xShankusu",
          },
        ],
      },
      {
        name: "Tray",
        title: "CTO",
        description:
          "Tray has over 3 years of experience in blockchain technology building DeFi products, infrastructure solutions and Web3 games.",
        avatar:
          "https://bafybeiaikrns6poacfzvjwzbzndhqbx3cgvvvkuh3mazne7qqrnvty4ite.ipfs.nftstorage.link/",

        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xTray_degen",
          },
        ],
      },
      {
        name: "Robin",
        title: "Head of Partnership",
        description:
          "A marketing expert that has been helping many web3 game projects broaden their network and distribute the game to hundreds of thousand users.",
        avatar:
          "https://bafybeibciji3i2zmdbmudtgb3bozka7musvfaacqtpipghhvuk5do2rkia.ipfs.nftstorage.link/",
        links: [
          {
            title: "Twitter",
            type: ExternalLinkType.Twitter,
            url: "https://twitter.com/0xRobin_",
          },
        ],
      },
    ],
  }

export const launchpadCollectionAllowlistMock: Record<string, number> = {
  "0:1c2f96327f431424c091814af772eb1db36690ea7756ce05356871b501f224eb": 2,
  "0:1c2f96327f431424c091814af772eb1db36690ea7756ce05356871b501f224ec": 4,
}
