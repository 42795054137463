import { useMemo } from "react"
import { launchpadCollectionsService } from "@/services/launchpad-collections-service"
import { useQuery } from "react-query"

export const useLaunchpadCollections = () => {
  const queryResult = useQuery("launchpadCollections", async () => {
    return launchpadCollectionsService.getLaunchpadCollections()
  })

  return useMemo(() => {
    return {
      launchpadCollections: queryResult.data,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
    }
  }, [queryResult.data, queryResult.error, queryResult.isLoading])
}
