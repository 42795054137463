import React from "react"

import { cn } from "@/utils/tw-utils"
import { Diode } from "@/components/ui/diode"

type EndedIndicatorProps = React.ComponentPropsWithoutRef<"span">

const EndedIndicator = ({ className, ...props }: EndedIndicatorProps) => {
  return (
    <span
      className={cn("flex items-center gap-2 whitespace-normal", className)}
      {...props}
    >
      <Diode state="off" color="input" className="-mx-0.5" />
      Ended
    </span>
  )
}
EndedIndicator.displayName = "EndedIndicator"

export { EndedIndicator }
