import { useRef } from "react"
import { Link } from "react-router-dom"
import { useHover } from "usehooks-ts"

import {
  LaunchpadCollection,
  LaunchpadCollectionStatus,
} from "@/types/launchpad-collection"
import { MediaSize } from "@/types/media"

import { formatDateShort } from "@/utils/date-utils"
import { cn } from "@/utils/tw-utils"
import { useLaunchStatus } from "@/hooks/use-launch-status"
import { Balance } from "@/components/ui/balance"
import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card"
import { Media } from "@/components/ui/media"
import { LiveIndicator } from "@/components/launchpad/live-indicator"

type LaunchCardProps = React.ComponentPropsWithoutRef<"div"> & {
  collection: LaunchpadCollection
}

const LaunchCard = ({ collection, className, ...props }: LaunchCardProps) => {
  const { status, startDate, currentStage } = useLaunchStatus(collection)
  const isLive = status === LaunchpadCollectionStatus.Live

  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  return (
    <Link
      to={`/launchpad/${collection.slug}`}
      className="relative flex w-full rounded-md outline-none ring-offset-background focus-visible:ring-1 focus-visible:ring-ring"
      ref={hoverRef}
    >
      {isHover && (
        <div className="pointer-events-none absolute left-4 right-4 top-4 -z-10 animate-glow-on opacity-[0.3] blur-2xl">
          <Media
            media={collection.image}
            size={MediaSize.Md}
            className="object-cover duration-500"
            skeleton={false}
          />
        </div>
      )}

      <Card
        className={cn(
          "group relative z-10 flex w-full flex-col overflow-hidden bg-frozen/60 backdrop-blur transition-transform duration-300 hover:scale-[1.025]",
          className
        )}
        {...props}
      >
        <div className="block aspect-square w-full overflow-hidden">
          <Media
            media={collection.image}
            size={MediaSize.Md}
            className="bg-accent object-cover"
          />
          <span className="sr-only">{collection.name}</span>
        </div>

        <CardContent className="flex flex-col justify-between px-3 py-3 sm:px-6 sm:py-5">
          <CardTitle className="truncate text-sm font-semibold transition-colors md:text-base">
            {collection.name}
          </CardTitle>
        </CardContent>

        <CardFooter className="mt-auto justify-between gap-1 px-3 pb-3 pt-0 text-sm font-medium sm:px-6 sm:pb-6">
          {isLive ? (
            <LiveIndicator className="text-xs sm:text-sm" />
          ) : (
            <span className="whitespace-nowrap text-xs sm:text-sm">
              {formatDateShort(startDate)}
            </span>
          )}

          <Balance
            value={currentStage?.price ?? collection.price}
            _icon={{
              className: "w-3.5 h-3.5 sm:w-4 sm:h-4",
            }}
            className="text-xs sm:text-sm"
          />
        </CardFooter>
      </Card>
    </Link>
  )
}
LaunchCard.displayName = "LaunchCard"

export { LaunchCard }
