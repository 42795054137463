import React from "react"

import { cn } from "@/utils/tw-utils"

type StyledHtmlProps = {
  html: string
} & React.ComponentPropsWithoutRef<"div">

const styledHtmlStyle =
  "leading-relaxed [&_h2]:mb-8 [&_h2]:mt-12 [&_h2]:text-2xl [&_h2]:font-bold md:[&_h2]:text-3xl [&_h4]:mb-4 [&_h4]:mt-8 [&_h4]:text-xl [&_h4]:font-bold [&_img]:rounded-md [&_p]:mb-4 [&_ul]:list-disc [&_ul]:pl-3 [&_*]:leading-relaxed"

const StyledHtml = React.forwardRef<HTMLDivElement, StyledHtmlProps>(
  ({ html, className, ...props }: StyledHtmlProps, ref) => {
    return (
      <div
        ref={ref}
        className={cn(styledHtmlStyle, className)}
        {...props}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    )
  }
)
StyledHtml.displayName = "StyledHtml"

export { StyledHtml, styledHtmlStyle }
