import { useMemo } from "react"
// @ts-ignore
import { google } from "calendar-link"
import { CalendarIcon } from "lucide-react"
import { Link } from "react-router-dom"
import { useMediaQuery } from "usehooks-ts"

import {
  LaunchpadCollection,
  LaunchpadCollectionStatus,
} from "@/types/launchpad-collection"
import { MediaSize } from "@/types/media"

import { formatDateShort } from "@/utils/date-utils"
import { cn } from "@/utils/tw-utils"
import { useLaunchStatus } from "@/hooks/use-launch-status"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Countdown } from "@/components/ui/countdown"
import { Media } from "@/components/ui/media"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { EndedIndicator } from "@/components/launchpad/ended-indicator"
import { LiveIndicator } from "@/components/launchpad/live-indicator"

const launchBannerStyle =
  "relative aspect-[16/24] overflow-hidden rounded-md md:aspect-[16/10] xl:aspect-[16/7]"

type FeaturedCollectionBannerProps = {
  collection: LaunchpadCollection
} & React.ComponentPropsWithRef<"article">

const FeaturedCollectionBanner = ({
  collection,
  className,
  ...props
}: FeaturedCollectionBannerProps) => {
  const { status, currentStage, nextStage, startDate } =
    useLaunchStatus(collection)

  const smBp = useMediaQuery("(max-width: 768px)")
  const mdBp = useMediaQuery("(max-width: 1280px)")
  const lgBp = useMediaQuery("(min-width: 1280px)")

  const mediaSize = useMemo(() => {
    if (smBp) return MediaSize.Sm
    if (mdBp) return MediaSize.Md
    if (lgBp) return MediaSize.Lg
    return MediaSize.Lg
  }, [smBp, mdBp, lgBp])

  const calendarLink = useMemo(() => {
    if (!nextStage) return

    return google({
      title: `${collection.name} – ${nextStage.displayName}`,
      start: new Date(nextStage.startTime),
      duration: [1, "hour"],
      description: collection.shortDescription ?? "",
    })
  }, [collection, nextStage])

  return (
    <article
      className={cn(launchBannerStyle, "group bg-background", className)}
      {...props}
    >
      {collection.banner ? (
        <Link to={`/launchpad/${collection.slug}`}>
          <Media
            media={collection.banner}
            size={mediaSize}
            className="h-full w-full transition-all duration-500 group-hover:scale-[1.025] group-hover:opacity-80"
          />

          <span className="sr-only">{collection.banner?.alt}</span>
        </Link>
      ) : null}

      <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-accent/75 to-transparent" />

      <div className="pointer-events-none absolute bottom-0 left-0 right-0 z-10 py-9 md:px-7 md:py-14">
        <div className="container flex flex-col gap-4 px-6 md:flex-row md:items-end md:justify-between">
          <div className="flex flex-col items-start gap-2">
            <h2 className="max-w-[36rem] text-2xl font-bold sm:text-3xl md:text-3xl">
              {collection.name}
            </h2>

            <div className="flex items-center gap-4">
              {status === LaunchpadCollectionStatus.Live && (
                <>
                  <Badge variant="frozen" className="h-9 px-3 text-sm">
                    <LiveIndicator />
                  </Badge>

                  <span className="text-sm font-medium">
                    {currentStage!.displayName}
                  </span>
                </>
              )}

              {status === LaunchpadCollectionStatus.Upcoming ||
                (status === LaunchpadCollectionStatus.Paused && (
                  <>
                    <Badge
                      variant="frozen"
                      className="pointer-events-auto h-9 px-1 text-sm"
                    >
                      <Countdown date={startDate} />
                    </Badge>

                    <span className="whitespace-nowrap text-sm font-medium">
                      {formatDateShort(startDate)}
                    </span>
                  </>
                ))}

              {status === LaunchpadCollectionStatus.Ended && (
                <Badge variant="frozen" className="h-9 px-3 text-sm">
                  <EndedIndicator />
                </Badge>
              )}
            </div>

            <p className="line-clamp-3 hidden max-w-[30rem] pt-1 text-sm md:block">
              {collection.shortDescription}
            </p>
          </div>

          <div className="pointer-events-auto flex items-center gap-4 md:flex-row-reverse">
            <Button size="lg" variant="gradient" asChild>
              <Link to={`/launchpad/${collection.slug}`}>View launchpad</Link>
            </Button>

            {status === LaunchpadCollectionStatus.Upcoming ||
              (status === LaunchpadCollectionStatus.Paused && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-11 w-11 bg-frozen/60 backdrop-blur"
                      asChild
                    >
                      <a href={calendarLink} target="_blank" rel="noreferrer">
                        <span className="sr-only">Add to calendar</span>
                        <CalendarIcon className="h-4 w-4" />
                      </a>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Add to calendar</TooltipContent>
                </Tooltip>
              ))}
          </div>
        </div>
      </div>
    </article>
  )
}
FeaturedCollectionBanner.displayName = "FeaturedCollectionBanner"

export { FeaturedCollectionBanner, launchBannerStyle }
