import { cn } from "@/utils/tw-utils"

type GradientTextProps = React.ComponentPropsWithoutRef<"span">

const GradientText = ({ className, ...props }: GradientTextProps) => {
  return (
    <span
      className={cn(
        "bg-gradient-to-r from-primary to-secondary to-[120%] bg-clip-text text-transparent",
        className
      )}
      {...props}
    />
  )
}

export { GradientText }
