import {
  ArrowUpRight,
  CheckIcon,
  GemIcon,
  LucideIcon,
  MailIcon,
  TwitterIcon,
} from "lucide-react"

import { Task, TaskProvider } from "@/types/task"

import { cn } from "@/utils/tw-utils"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { VenomIcon } from "@/components/icons/venom-icon"

const taskProviderIcons: Record<TaskProvider, LucideIcon> = {
  [TaskProvider.Venom]: VenomIcon,
  [TaskProvider.Twitter]: TwitterIcon,
  [TaskProvider.Email]: MailIcon,
}

interface TaskCardProps extends React.ComponentPropsWithRef<"div"> {
  task: Task
  completed?: boolean
  disabled?: boolean
  loading?: boolean
  onCheck?: (task: Task) => void
}

const TaskCard = ({
  task,
  completed,
  disabled,
  onCheck,
  className,
  ...props
}: TaskCardProps) => {
  const {
    provider,
    externalLink,
    name,
    description,
    reward,
    buttonText,
    isActive,
  } = task

  const Icon = taskProviderIcons[provider]
  const hasLink = Boolean(externalLink)

  return (
    <Card className={cn("bg-background p-6 md:p-10 ", className)} {...props}>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex h-11 w-11 items-center justify-center rounded-full border text-primary">
          {Icon ? <Icon className="h-5 w-5" /> : null}
        </div>

        <div className="flex items-center gap-3">
          <div className="flex h-11 flex-none items-center rounded-full border pl-3 pr-4 text-sm">
            <GemIcon className="mr-2 h-4 w-4 text-secondary" />
            {reward}
          </div>

          {hasLink && (
            <Button asChild size="icon" className="h-11 w-11" variant="outline">
              <a href={externalLink}>
                <ArrowUpRight className="h-5 w-5" />
              </a>
            </Button>
          )}
        </div>
      </div>

      <h2 className="mb-4 text-lg font-semibold">{name}</h2>

      <p className="text-sm leading-relaxed text-muted-foreground">
        {description}
      </p>

      <div className="mt-9 w-full">
        {completed ? (
          <div className="relative flex h-11 w-full items-center justify-center rounded-full border text-sm font-medium text-muted-foreground !opacity-100">
            <CheckIcon className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2" />{" "}
            Done
          </div>
        ) : (
          <Button
            variant="gradient"
            className="w-full"
            size="lg"
            disabled={!isActive || disabled}
            onClick={() => onCheck?.(task)}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  )
}

TaskCard.displayName = "TaskCard"

export { TaskCard }
