import { apiService } from "@/services/api-service"

import { Task } from "@/types/task"

import { wait } from "@/utils/wait"
import { appConfig } from "@/config/app-config"
import { tasksMock } from "@/config/mocks/tasks-mocks"

const getMockedTasks = (): Task[] => {
  return tasksMock
}

const getTasks = async (): Promise<Task[]> => {
  if (appConfig.useMocks) {
    await wait(1000)
    return getMockedTasks()
  }

  const res = await apiService.get<Task[]>("/tasks")
  return res.data
}

export const tasksApi = {
  getTasks,
}
