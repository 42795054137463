import { createContext, useContext, useMemo, useState } from "react"
import { ProviderRpcClient } from "everscale-inpage-provider"
import { VenomConnect } from "venom-connect"

import { Account } from "@/types/venom"

import { useVenomConnect } from "@/hooks/venom/use-venom-connect"

type VenomProviderProps = {
  children: React.ReactNode
  initVenomConnect: () => Promise<VenomConnect>
}

type VenomProviderState = {
  account?: Account
  venomConnect?: VenomConnect
  venomProvider?: ProviderRpcClient
  isConnected: boolean
  connect: () => Promise<void>
  disconnect: () => Promise<void>
}

const initialState: VenomProviderState = {
  account: undefined,
  venomConnect: undefined,
  venomProvider: undefined,
  isConnected: false,
  connect: async () => {},
  disconnect: async () => {},
}

const VenomProviderContext = createContext<VenomProviderState>(initialState)

export function VenomProvider({
  initVenomConnect,
  children,
}: VenomProviderProps) {
  const [venomProvider, setVenomProvider] = useState<ProviderRpcClient>()

  const [account, setAccount] = useState<Account>()

  const { disconnect, connect, venomConnect, isConnected } = useVenomConnect(
    initVenomConnect,
    venomProvider,
    setVenomProvider,
    setAccount
  )

  const value: VenomProviderState = useMemo(
    () => ({
      account,
      connect,
      disconnect,
      venomConnect: venomConnect ?? initialState.venomConnect,
      venomProvider: venomProvider ?? initialState.venomProvider,
      isConnected,
    }),
    [account, connect, disconnect, venomConnect, venomProvider, isConnected]
  )

  return (
    <VenomProviderContext.Provider value={value}>
      {children}
    </VenomProviderContext.Provider>
  )
}

export const useVenom = () => {
  const context = useContext(VenomProviderContext)

  if (context === undefined) {
    throw new Error("useVenom must be used within a VenomProvider")
  }

  return context
}
