import { useMemo } from "react"
import nftAbi from "@/abi/nft.abi.json"
import BigNumber from "bignumber.js"
import { Address } from "everscale-inpage-provider"
import { useMutation } from "react-query"

import { getAddress, toNano } from "@/utils/venom-utils"
import { useVenom } from "@/providers/venom-provider"

interface MintNftArgs {
  contractAddress: Address | string
  walletAddress: Address | string
  nftsAmount: number
  nftPrice: number
  onSuccess?: (data: unknown) => void
  onError?: (error: Error) => void
}

export const useBatchMintNftCall = ({
  contractAddress,
  walletAddress,
  nftsAmount,
  nftPrice,
  onSuccess,
  onError,
}: MintNftArgs) => {
  const { venomProvider } = useVenom()

  const gasPrice = useMemo(
    () => new BigNumber(toNano(5)).multipliedBy(nftsAmount).toString(),
    [nftsAmount]
  )
  const mintPrice = useMemo(
    () => new BigNumber(nftPrice).multipliedBy(nftsAmount).toString(),
    [nftsAmount, nftPrice]
  )

  const totalPrice = useMemo(
    () => BigNumber(mintPrice).plus(gasPrice).toString(),
    [mintPrice, gasPrice]
  )

  const {
    mutate: mint,
    isLoading,
    isError,
    error,
    isSuccess,
    data: result,
  } = useMutation({
    mutationFn: async () => {
      if (!venomProvider) {
        throw new Error("Venom provider is not available now")
      }

      const result = await venomProvider.sendMessage({
        sender: getAddress(walletAddress),
        recipient: getAddress(contractAddress),
        amount: totalPrice,
        payload: {
          abi: JSON.stringify(nftAbi),
          method: "batchMintNft",
          params: {
            owner: walletAddress,
            amount: nftsAmount,
          },
        },
        bounce: false,
      })

      return result
    },
    onSuccess,
    onError,
    retry: false,
  })

  return useMemo(() => {
    return {
      mint,
      totalPrice,
      gasPrice,
      mintPrice,
      isLoading,
      isError,
      error,
      isSuccess,
      result,
    }
  }, [
    mint,
    isLoading,
    isError,
    error,
    isSuccess,
    result,
    totalPrice,
    gasPrice,
    mintPrice,
  ])
}
