import { ArrowUpRight } from "lucide-react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import { composePageTitle } from "@/utils/meta-utils"
import { useVenom } from "@/providers/venom-provider"
import { Button } from "@/components/ui/button"
import { GradientText } from "@/components/ui/gradient-text"
import { SocialLinks } from "@/components/common/social-links"
import { ProfileProgress } from "@/components/rewards/profile-progress"
import { TasksList } from "@/components/rewards/tasks-list"

const metaTitle = composePageTitle("Rewards")
const metaDescription =
  "Engage in a Gamified NFT Experience - Discover our loyalty and rewards system. Earn tokens and NFTs while trading, minting, and referring. Become a legend today."

const RewardsPage = () => {
  const { connect, isConnected } = useVenom()

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="container px-3 py-16 md:px-6 md:py-16">
        <div className="relative mb-12 flex flex-col items-start justify-start md:flex-row md:justify-between">
          <div>
            <h1 className="text-3xl font-bold">
              <GradientText>Step to the Rewards&nbsp;Oasis</GradientText>
            </h1>

            <p className="mt-4 max-w-lg text-sm leading-relaxed">
              Level up to boost your chances of winning unique NFTs exclusively
              on the Venom blockchain and embark on a journey of digital
              discovery.
            </p>

            <div className="flex gap-3">
              {!isConnected && (
                <Button variant="gradient" onClick={connect} className="mt-4">
                  Connect wallet
                </Button>
              )}

              <Button
                asChild
                variant="outline"
                className="mt-4 gap-2 md:absolute md:right-0 md:top-0 md:mt-0"
              >
                <Link to="/support">
                  Learn more <ArrowUpRight className="h-4 w-4" />
                </Link>
              </Button>
            </div>
          </div>
        </div>

        <ProfileProgress className="mb-12" />

        <div className="relative mb-8">
          <div>
            <h1 className="max-w-md text-xl font-bold md:text-2xl">
              Genesis Quests
            </h1>

            <p className="mt-4 max-w-lg text-sm leading-relaxed">
              More quests are on the horizon! Stay in the loop by following us
              on social media for the latest updates and quest announcements.
            </p>
          </div>

          <SocialLinks className="mt-4 md:absolute md:right-0 md:top-0 md:mt-0" />
        </div>

        <TasksList />
      </div>
    </>
  )
}

RewardsPage.displayName = "RewardsPage"

export { RewardsPage }
