import { profileService } from "@/services/profile-service"
import { zodResolver } from "@hookform/resolvers/zod"
import { KeyIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import * as z from "zod"

import { Profile } from "@/types/profile"

import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Loading } from "@/components/ui/loading"

interface VerifyEmailFormProps {
  profile: Profile
  onSuccess: () => void
}

const formSchema = z.object({
  code: z
    .string()
    .min(6, {
      message: "Please enter a valid verification code.",
    })
    .max(6, {
      message: "Please enter a valid verification code.",
    }),
})

const VerifyEmailForm = ({ profile, onSuccess }: VerifyEmailFormProps) => {
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: z.infer<typeof formSchema>) => {
      return profileService.verifyEmail(profile.walletAddress, data)
    },
    onSuccess: () => {
      onSuccess()

      toast({
        title: "Email verified",
        description: "Your email has been successfully verified.",
      })
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: error.message,
      })
    },
  })

  const handleVerifyEmailFormSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleVerifyEmailFormSubmit)}
        className="flex flex-col gap-5 md:flex-row"
      >
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => {
            return (
              <FormItem className="relative flex w-full flex-col gap-2 space-y-0">
                <FormControl className="relative">
                  <Input
                    type="text"
                    placeholder="Verification code"
                    className="h-11 px-4 pl-11"
                    maxLength={6}
                    {...field}
                  />
                </FormControl>

                <KeyIcon className="absolute left-4 top-3.5 !mt-0 flex h-4 w-4 text-primary" />

                <FormMessage />
              </FormItem>
            )
          }}
        />

        {isLoading ? (
          <Loading
            className="h-11 w-full flex-none rounded-full bg-frozen/60 px-4 md:w-32"
            _spinner={{
              className: "w-4 h-4",
            }}
          />
        ) : (
          <Button
            type="submit"
            size="lg"
            variant="gradient"
            className="w-full flex-none md:w-32"
          >
            Verify
          </Button>
        )}
      </form>
    </Form>
  )
}

VerifyEmailForm.displayName = "VerifyEmailForm"

export { VerifyEmailForm }
