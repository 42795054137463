import { useMemo } from "react"
import { Helmet } from "react-helmet-async"

import { LaunchpadCollectionStatus } from "@/types/launchpad-collection"

import { cn } from "@/utils/tw-utils"
import { getLaunchStatus } from "@/hooks/use-launch-status"
import { useLaunchpadCollections } from "@/hooks/use-launchpad-collections"
import { Skeleton } from "@/components/ui/skeleton"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { launchBannerStyle } from "@/components/launchpad/launch-banner"
import { LaunchesGrid } from "@/components/launchpad/launches-grid"
import {
  LaunchesSwiper,
  launchesSwiperStyle,
} from "@/components/launchpad/launches-swiper"

const metaTitle = "Gateway.io – Unlocking NFT potential on Venom blockchain"
const metaDescription =
  "Redefining NFTs with AI Integration - Unlock the future of NFTs with our AI-powered launchpad. Explore Utility NFTs and innovative rewards on the Venom blockchain."

const LaunchpadPage = () => {
  const { launchpadCollections } = useLaunchpadCollections()

  const featuredCollections = useMemo(
    () => launchpadCollections?.filter((collection) => collection.isFeatured),
    [launchpadCollections]
  )

  const liveOrUpcomingCollections = useMemo(
    () =>
      launchpadCollections?.filter((collection) => {
        const { status } = getLaunchStatus(collection)
        return [
          LaunchpadCollectionStatus.Live,
          LaunchpadCollectionStatus.Upcoming,
          LaunchpadCollectionStatus.Paused,
        ].includes(status)
      }),
    [launchpadCollections]
  )

  const pastCollections = useMemo(
    () =>
      launchpadCollections?.filter((collection) => {
        const { status } = getLaunchStatus(collection)
        return status === LaunchpadCollectionStatus.Ended
      }),
    [launchpadCollections]
  )

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="mx-auto max-w-[1560px] px-3">
        {featuredCollections ? (
          <LaunchesSwiper collections={featuredCollections} />
        ) : (
          <LaunchpadPageLoadingState />
        )}
      </div>

      {liveOrUpcomingCollections && pastCollections ? (
        <div className="container relative z-10 mb-16 mt-5 px-3 md:px-6">
          <Tabs defaultValue="live-and-upcoming">
            <TabsList className="border-b">
              <TabsTrigger value="live-and-upcoming" className="h-12 px-5">
                Live & upcoming
              </TabsTrigger>
              <TabsTrigger value="past" className="h-12 px-5">
                Past
              </TabsTrigger>
            </TabsList>
            <TabsContent value="live-and-upcoming" className="mt-6">
              <LaunchesGrid collections={liveOrUpcomingCollections} />
            </TabsContent>
            <TabsContent value="past" className="mt-6">
              <LaunchesGrid collections={pastCollections} />
            </TabsContent>
          </Tabs>
        </div>
      ) : null}
    </>
  )
}
LaunchpadPage.displayName = "LaunchpadPage"

const LaunchpadPageLoadingState = () => {
  return <Skeleton className={cn(launchBannerStyle, launchesSwiperStyle)} />
}
LaunchpadPageLoadingState.displayName = "LaunchpadPageLoadingState"

export { LaunchpadPage }
