import { useCallback, useMemo, useState } from "react"
import { launchpadCollectionsService } from "@/services/launchpad-collections-service"
import { Address } from "everscale-inpage-provider"
import { HeartCrackIcon, PartyPopperIcon } from "lucide-react"
import { useQuery, useQueryClient } from "react-query"

import {
  LaunchpadCollectionStage,
  LaunchpadCollectionStageType,
  LaunchpadCollectionWithDetails,
} from "@/types/launchpad-collection"

import { cn } from "@/utils/tw-utils"
import { useVenom } from "@/providers/venom-provider"
import { useToast } from "@/hooks/use-toast"
import { useBatchMintNftCall } from "@/hooks/venom/use-batch-mint-nft-call"
import { useMintNftCall } from "@/hooks/venom/use-mint-nft-call"
import { Alert, AlertTitle } from "@/components/ui/alert"
import { AmountInput } from "@/components/ui/amount-input"
import { Button } from "@/components/ui/button"
import { Loading } from "@/components/ui/loading"

const maxMintLimit = 999

type LaunchMintingFormProps = {
  collection: LaunchpadCollectionWithDetails
  stage: LaunchpadCollectionStage
} & React.ComponentPropsWithoutRef<"form">

const LaunchMintingForm = ({
  collection,
  stage,
  className,
  ...props
}: LaunchMintingFormProps) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const [nftsAmount, setNftsAmount] = useState(1)
  const { account } = useVenom()

  const handleSuccess = useCallback(() => {
    toast({
      title: "NFT mint success 🎉",
      description: `You have successfully minted ${nftsAmount} NFTs of ${collection.name} collection.`,
    })

    queryClient.invalidateQueries({
      queryKey: [collection.tvm.contractAddress, "totalSupply"],
    })
  }, [nftsAmount, collection, queryClient, toast])

  const handleError = useCallback((error: any) => {
    toast({
      variant: "destructive",
      title: "Uh oh! Something went wrong.",
      description: error.message,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    mint: mintBatch,
    isLoading: isBatchMinting,
    // gasPrice: batchGasPrice,
  } = useBatchMintNftCall({
    contractAddress: collection.tvm.contractAddress,
    walletAddress: account!.address,
    nftsAmount: nftsAmount,
    nftPrice: stage.price,
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const {
    mint: mintSingle,
    isLoading: isSingleMinting,
    // gasPrice: singleGasPrice,
  } = useMintNftCall({
    contractAddress: collection.tvm.contractAddress,
    walletAddress: account!.address,
    nftPrice: stage.price,
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const isPublic = stage.type === LaunchpadCollectionStageType.Public
  const isAllowlist = stage.type === LaunchpadCollectionStageType.Allowlist
  const isLoading = isBatchMinting || isSingleMinting

  const { allowlistRecord, isFetched: isAllowlistFetched } = useStageAllowlist(
    stage,
    collection,
    account?.address
  )

  const mintLimit = useMemo(() => {
    return stage.type === LaunchpadCollectionStageType.Public
      ? stage.perWalletLimit || maxMintLimit
      : allowlistRecord || 0
  }, [stage, allowlistRecord])

  const canMint = useMemo(
    () => mintLimit >= nftsAmount,
    [mintLimit, nftsAmount]
  )

  const handleMint: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      if (nftsAmount > 1) {
        mintBatch()
      } else {
        mintSingle()
      }
    },
    [mintBatch, mintSingle, nftsAmount]
  )

  return (
    <>
      <form className={cn(className)} onSubmit={handleMint} {...props}>
        <div className="flex gap-4">
          <div className="w-full">
            <AmountInput
              min={1}
              max={mintLimit}
              value={nftsAmount}
              className="h-11 w-full"
              disabled={!canMint}
              onChange={setNftsAmount}
            />
          </div>
          <div className="w-full">
            {(isPublic || isAllowlistFetched) && !isLoading ? (
              <Button
                type="submit"
                variant="gradient"
                className="w-full gap-2"
                size="lg"
                disabled={!canMint}
              >
                Mint
              </Button>
            ) : (
              <Loading
                className="rounded-full bg-frozen/60"
                text="Please wait…"
                _spinner={{
                  className: "w-4 h-4",
                }}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {/* <span className="mt-1 w-1/2 text-center text-xs font-medium text-muted-foreground">
            GAS:{" "}
            {formatBalance(nftsAmount > 1 ? batchGasPrice : singleGasPrice)}{" "}
            VENOM
          </span> */}

          {isAllowlist && isAllowlistFetched && !allowlistRecord && (
            <Alert className="mt-4">
              <HeartCrackIcon className="h-4 w-4 text-destructive" />
              <AlertTitle>
                Your wallet is not included in the allowed list.
              </AlertTitle>
            </Alert>
          )}

          {isAllowlist && isAllowlistFetched && allowlistRecord && (
            <Alert className="mt-4">
              <PartyPopperIcon className="h-4 w-4" />
              <AlertTitle>
                Your wallet is on our allowlist. You can mint {mintLimit} NFTs.
              </AlertTitle>
            </Alert>
          )}

          {mintLimit > 0 && nftsAmount > mintLimit && (
            <Alert className="mt-4">
              <HeartCrackIcon className="h-4 w-4 text-destructive" />
              <AlertTitle>
                You can mint up to {mintLimit} NFTs on this wallet.
              </AlertTitle>
            </Alert>
          )}
        </div>
      </form>
    </>
  )
}
LaunchMintingForm.displayName = "LaunchMintingForm"

const useStageAllowlist = (
  stage: LaunchpadCollectionStage,
  collection: LaunchpadCollectionWithDetails,
  walletAddress?: Address | string
) => {
  const {
    data: allowlist,
    isLoading,
    isFetched,
  } = useQuery(
    ["launchpad-collection-allowlist", collection.id, stage.index],
    async () => {
      const allowlist =
        await launchpadCollectionsService.getLaunchpadCollectionAllowlist(
          stage.allowlistUrl!
        )

      return allowlist
    },
    {
      enabled: stage.type === "allowlist",
    }
  )

  const allowlistRecord = useMemo(() => {
    if (!walletAddress || !allowlist) {
      return undefined
    }

    return allowlist[String(walletAddress)]
  }, [allowlist, walletAddress])

  return {
    isLoading,
    isFetched,
    allowlist,
    allowlistRecord,
  }
}

export { LaunchMintingForm }
