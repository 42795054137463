import { useMemo } from "react"
import { profileService } from "@/services/profile-service"
import { Address } from "everscale-inpage-provider"
import { useQuery } from "react-query"

export const useProfile = (walletAddress?: Address | string) => {
  const queryResult = useQuery(
    ["profile", String(walletAddress)],
    async () => {
      if (!walletAddress) {
        return
      }
      return profileService.getOrCreateProfile(String(walletAddress))
    },
    {
      retry: false,
      enabled: !!walletAddress,
    }
  )

  return useMemo(() => {
    return {
      profile: queryResult.data,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
      isFetched: queryResult.isFetched,
    }
  }, [
    queryResult.data,
    queryResult.error,
    queryResult.isLoading,
    queryResult.isFetched,
  ])
}
