import { useMemo } from "react"

import {
  LaunchpadCollection,
  LaunchpadCollectionStage,
  LaunchpadCollectionStatus,
} from "@/types/launchpad-collection"

import { timestampToDate } from "@/utils/date-utils"
import { getLaunchStageStatus } from "@/hooks/use-launch-stage-status"

export type LaunchpadCollectionStatusResult = (
  | {
      status: LaunchpadCollectionStatus.Ended
      currentStage: undefined
      nextStage: undefined
    }
  | {
      status: LaunchpadCollectionStatus.Live
      currentStage: LaunchpadCollectionStage
      nextStage: LaunchpadCollectionStage | undefined
    }
  | {
      status:
        | LaunchpadCollectionStatus.Upcoming
        | LaunchpadCollectionStatus.Paused
      currentStage: undefined
      nextStage: LaunchpadCollectionStage
    }
  | {
      status: LaunchpadCollectionStatus
      currentStage: LaunchpadCollectionStage | undefined
      nextStage: LaunchpadCollectionStage | undefined
    }
) & {
  launchDate: Date
  startDate: Date
  endDate: Date
}

export const getLaunchStatus = (
  collection: LaunchpadCollection
): LaunchpadCollectionStatusResult => {
  const { stages } = collection

  const now = new Date()

  const stagesStatus = stages
    .sort((a, b) => {
      return a.startTime - b.startTime
    })
    .map((stage) => {
      return getLaunchStageStatus(stage)
    })

  const launchDate = timestampToDate(stages[0].startTime)
  const startDate = new Date(
    stagesStatus.find((stage) => {
      return stage.status === LaunchpadCollectionStatus.Upcoming
    })?.startDate || timestampToDate(stages[0].startTime)
  )
  const endDate = timestampToDate(stages[stages.length - 1].endTime)

  const currentStage = stages.find((stage) => {
    const stageStart = timestampToDate(stage.startTime)
    const stageEnd = timestampToDate(stage.endTime)

    return (
      now.getTime() > stageStart.getTime() && now.getTime() < stageEnd.getTime()
    )
  })

  const nextStage = stages.find((stage) => {
    const stageStart = timestampToDate(stage.startTime)
    return now.getTime() < stageStart.getTime()
  })

  if (currentStage) {
    return {
      status: LaunchpadCollectionStatus.Live,
      launchDate,
      startDate,
      endDate,
      currentStage,
      nextStage,
    }
  }

  if (
    nextStage &&
    launchDate.getTime() < timestampToDate(nextStage.endTime).getTime()
  ) {
    return {
      status: LaunchpadCollectionStatus.Paused,
      launchDate,
      startDate,
      endDate,
      currentStage,
      nextStage,
    }
  }

  if (nextStage) {
    return {
      status: LaunchpadCollectionStatus.Upcoming,
      launchDate,
      startDate,
      endDate,
      currentStage,
      nextStage,
    }
  }

  return {
    status: LaunchpadCollectionStatus.Ended,
    launchDate,
    startDate,
    endDate,
    currentStage,
    nextStage,
  }
}

export const useLaunchStatus = (collection: LaunchpadCollection) => {
  return useMemo(() => getLaunchStatus(collection), [collection])
}
