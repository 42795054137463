export interface Task {
  id: string
  type: TaskType
  provider: TaskProvider
  reward: number
  requiredTasks?: string[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData?: Record<string, any>

  isActive: boolean

  name: string
  description: string
  buttonText: string
  hint?: string
  externalLink?: string
}

export enum TaskType {
  Follow = "follow",
  Login = "login",
  Subscribe = "subscribe",
  Connect = "connect",
  Mint = "mint",
}

export enum TaskProvider {
  Twitter = "twitter",
  Email = "email",
  Venom = "venom",
}
