import React from "react"

interface LogoProps extends React.ComponentPropsWithoutRef<"svg"> {}

const Logo = React.forwardRef<SVGSVGElement, LogoProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 364 70.8"
    ref={ref}
    {...props}
  >
    <path
      d="m124 53.1-.7-4.8c-4 4-7.9 5.7-13.6 5.7C98.5 54 91 46.6 91 35.7s7.8-18.5 19.4-18.5c5.5 0 10.1 1.7 13.3 4.8 2 2.1 3.1 3.9 4 7.2H122c-.8-2.4-1.6-3.5-3.3-4.8-2.3-1.7-5.1-2.6-8.4-2.6-8.3 0-13.8 5.5-13.8 13.6s5.5 14 13.9 14c4.5 0 8.1-1.6 10.5-4.6 1.3-1.7 1.9-3.2 2.4-6h-11.5V34h16.3v19.1H124zM143.5 43 139 53.1h-6.1l16.3-35h6.1l16.4 35H165L160.5 43h-17zm8.4-18.9-6.4 14.3h12.9l-6.5-14.3zm29.5 29V23H168v-4.9h32.3V23h-13.5v30.1h-5.4zm24.1 0v-35h28.6V23h-23.2v9.8h21.9v4.9h-21.9v10.6h23.7v4.9h-29.1v-.1zm70.9 0-10-29.1-9.8 29.1h-6.2l-11.8-35h6.1l9 28.9 9.8-28.9h6.1l9.9 28.9 9.2-28.9h5.5l-11.8 35h-6zm27-10.1-4.6 10.1h-6.1l16.3-35h6.1l16.4 35H325L320.4 43h-17zm8.4-18.9-6.4 14.3h12.9l-6.5-14.3zm30.8 29V38.6l-15.4-20.5h7l11.3 15.8 11.4-15.8h6.6L348 38.6v14.5h-5.4z"
      style={{
        fill: "#f6f6f6",
      }}
    />
    <linearGradient
      id="a"
      x1={0}
      x2={50}
      y1={50}
      y2={0}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#11a77c",
        }}
      />
      <stop
        offset={0.138}
        style={{
          stopColor: "#15a082",
        }}
      />
      <stop
        offset={0.337}
        style={{
          stopColor: "#218d93",
        }}
      />
      <stop
        offset={0.572}
        style={{
          stopColor: "#346eae",
        }}
      />
      <stop
        offset={0.793}
        style={{
          stopColor: "#4a4ace",
        }}
      />
    </linearGradient>
    <path
      d="m58 15.5-8.8-5-.5-.3-9.2 5.3-8.7 5-13 7.5v14.9l13 7.4 13-7.4v-8.7l-9.2 5.3-2.3 1.3-1.6-.9-3.7-2.3v-4.4l3.8-2.2 9.2-5.3 8.7-5 3.8 2.1v24.9L30.9 60.3 9.2 47.8V23l21.7-12.4L40 5.3 30.9 0 0 17.7v35.4l30.9 17.7 30.9-17.7V17.7L58 15.5z"
      style={{
        fill: "url(#a)",
      }}
    />
  </svg>
))

Logo.displayName = "Logo"

export { Logo }
