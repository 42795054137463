import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { composePageTitle } from "@/utils/meta-utils"
import { cn } from "@/utils/tw-utils"
import { useLaunch } from "@/hooks/use-launch"
import { Skeleton } from "@/components/ui/skeleton"
import { LaunchDetails } from "@/components/launchpad/launch-details"
import {
  LaunchMediaGallery,
  launchMediaGalleryItemStyle,
  launchMediaGalleryStyle,
} from "@/components/launchpad/launch-media-gallery"
import { LaunchOverview } from "@/components/launchpad/launch-overview"

const LaunchPage = () => {
  const { slug } = useParams<{
    slug: string
  }>()

  const { launchpadCollection } = useLaunch(slug)

  const metaTitle = launchpadCollection?.name
    ? launchpadCollection.name
    : "Launch"
  const metaDescription = launchpadCollection?.shortDescription ?? ""

  return (
    <>
      <Helmet>
        <title>{composePageTitle(metaTitle)}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="container px-3 md:px-6 lg:mt-12">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-10 xl:gap-16">
          {launchpadCollection ? (
            <>
              <LaunchMediaGallery media={launchpadCollection.media} />
              <LaunchDetails collection={launchpadCollection} />
            </>
          ) : (
            <>
              <LaunchPageLoadingState />
            </>
          )}
        </div>

        {launchpadCollection ? (
          <>
            <LaunchOverview collection={launchpadCollection} />
          </>
        ) : null}
      </div>
    </>
  )
}
LaunchPage.displayName = "LaunchPage"

const LaunchPageLoadingState = () => {
  return (
    <>
      <Skeleton
        className={cn(launchMediaGalleryStyle, launchMediaGalleryItemStyle)}
      />

      <div className="flex flex-col items-start gap-8 lg:mt-4">
        <div className="flex w-full flex-col gap-3">
          <Skeleton className="h-10 w-full rounded-md md:w-8/12" />
          <Skeleton className="h-8 w-8/12 rounded-md md:w-1/3" />
        </div>
      </div>
    </>
  )
}
LaunchPageLoadingState.displayName = "LaunchPageLoadingState"

export { LaunchPage }
