import { apiService } from "@/services/api-service"

import { Profile } from "@/types/profile"

import { wait } from "@/utils/wait"
import { appConfig } from "@/config/app-config"
import { profileMock } from "@/config/mocks/profile-mocks"

const getMockedProfile = (walletAddress: string): Profile => {
  return {
    ...profileMock,
    walletAddress: String(walletAddress),
  }
}

const getProfile = async (walletAddress: string): Promise<Profile> => {
  if (appConfig.useMocks) {
    await wait(200)
    return getMockedProfile(walletAddress)
  }

  const res = await apiService.get<Profile>(
    `/profile/${walletAddress}/wallet-address`
  )

  return res.data
}

const getOrCreateProfile = async (walletAddress: string): Promise<Profile> => {
  if (appConfig.useMocks) {
    await wait(200)
    return getMockedProfile(walletAddress)
  }

  let user: Profile

  try {
    const res = await apiService.get<Profile>(
      `/profile/${walletAddress}/wallet-address`
    )
    user = res.data
  } catch (error: any) {
    if (error.response?.status === 404) {
      try {
        const res = await apiService.post<Profile>(`/profile`, {
          walletAddress,
        })

        user = res.data
      } catch (error: any) {
        console.error(error)
        throw error
      }
    } else {
      console.error(error)
      throw error
    }
  }

  return user
}

const addEmail = async (
  walletAddress: string,
  data: { email: string }
): Promise<Profile> => {
  if (appConfig.useMocks) {
    await wait(1000)
    return getMockedProfile(walletAddress)
  }

  const res = await apiService.post<Profile>(
    `/profile/${walletAddress}/email`,
    data
  )

  return res.data
}

const verifyEmail = async (
  walletAddress: string,
  data: { code: string }
): Promise<Profile> => {
  if (appConfig.useMocks) {
    await wait(1000)
    return getMockedProfile(walletAddress)
  }

  const res = await apiService.post<Profile>(
    `/profile/${walletAddress}/email/verify`,
    data
  )

  return res.data
}

export const profileService = {
  getProfile,
  getOrCreateProfile,
  addEmail,
  verifyEmail,
}
