import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ProviderRpcClient } from "everscale-inpage-provider"
import { VenomConnect } from "venom-connect"

import { Account } from "@/types/venom"

import { getProviderAccount } from "@/utils/venom-utils"

export const useVenomConnect = (
  initVenomConnect: () => Promise<VenomConnect>,
  venomProvider: ProviderRpcClient | undefined,
  setVenomProvider: Dispatch<SetStateAction<ProviderRpcClient | undefined>>,
  setAccount: Dispatch<SetStateAction<Account | undefined>>
) => {
  const [venomConnect, setVenomConnect] = useState<VenomConnect>()
  const [isConnected, setIsConnected] = useState(false)

  const init = async () => {
    const venomConnect = await initVenomConnect()
    setVenomConnect(venomConnect)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onConnect = async (provider: any) => {
    setVenomProvider(provider)

    const account = await getProviderAccount(provider)
    if (account) {
      setAccount(account)
      setIsConnected(true)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkAuth = async (venomConnect: any) => {
    const auth = await venomConnect?.checkAuth()
    if (auth) await getProviderAccount(venomConnect)
  }

  const connect = async () => {
    if (!venomConnect) return
    await venomConnect.connect()
  }

  const disconnect = async () => {
    venomProvider?.disconnect()
    setVenomProvider(undefined)
    setIsConnected(false)
    setAccount(undefined)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (venomConnect) {
      checkAuth(venomConnect)
    }

    const off = venomConnect?.on("connect", onConnect)
    return () => {
      off?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venomConnect])

  return {
    connect,
    disconnect,
    isConnected,
    venomConnect,
  }
}
