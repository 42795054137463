import { useState } from "react"
import { tasksApi } from "@/services/tasks-service"
import { useQuery, useQueryClient } from "react-query"
import { useToggle } from "usehooks-ts"

import { Task } from "@/types/task"

import { useVenom } from "@/providers/venom-provider"
import { useProfile } from "@/hooks/use-profile"
import { Skeleton } from "@/components/ui/skeleton"
import { EmailSubscriptionDialog } from "@/components/rewards/email-subscription-dialog"
import { TaskCard } from "@/components/rewards/task-card"

const TasksList = () => {
  const queryClient = useQueryClient()
  const [selectedTask, setSelectedTask] = useState<Task>()

  const [isEmailSubDialogOpen, toggleEmailSubDialog] = useToggle(false)

  const { data: tasks, isFetched } = useQuery("tasks", async () => {
    return tasksApi.getTasks()
  })

  const { account } = useVenom()
  const { profile } = useProfile(account?.address)

  const hasTasks = Boolean(tasks?.length)

  const handleCheck = async (task: Task) => {
    setSelectedTask(task)

    if (task.provider === "email") {
      toggleEmailSubDialog()
    }
  }

  return (
    <>
      <ul className="grid w-full gap-8 md:grid-cols-2 lg:grid-cols-3">
        {isFetched ? (
          <>
            {hasTasks &&
              tasks?.map((task) => (
                <li key={task.id}>
                  <TaskCard
                    task={task}
                    completed={profile?.completedTasks.includes(task.id)}
                    className="h-full"
                    onCheck={handleCheck}
                  />
                </li>
              ))}
          </>
        ) : (
          <TasksLoadingState />
        )}
      </ul>

      {profile && selectedTask && isEmailSubDialogOpen && (
        <EmailSubscriptionDialog
          onSuccess={() => {
            queryClient.invalidateQueries(["profile", profile?.walletAddress])
            toggleEmailSubDialog()
            setSelectedTask(undefined)
          }}
          onClose={toggleEmailSubDialog}
        />
      )}
    </>
  )
}
TasksList.displayName = "TasksList"

const TasksLoadingState = () => {
  return (
    <>
      <Skeleton className="h-[20rem] w-full rounded-lg" />
      <Skeleton className="h-[20rem] w-full rounded-lg" />
      <Skeleton className="h-[20rem] w-full rounded-lg" />
    </>
  )
}
TasksLoadingState.displayName = "TasksLoadingState"

export { TasksList }
