import { GhostIcon } from "lucide-react"

import { cn } from "@/utils/tw-utils"

type EmptyStateProps = React.ComponentPropsWithoutRef<"div"> & {
  text: string
  icon?: React.ComponentType<React.ComponentPropsWithoutRef<"svg">>
  _icon?: React.ComponentPropsWithoutRef<"svg">
}

const EmptyState = ({
  text,
  icon: Icon = GhostIcon,
  _icon = {},
  className,
}: EmptyStateProps) => {
  const { className: iconClassName, ...iconProps } = _icon

  return (
    <div
      className={cn(
        "flex w-full flex-col items-center justify-center gap-6 text-center text-sm",
        className
      )}
    >
      <Icon
        className={cn("h-7 w-7 animate-tada", iconClassName)}
        {...iconProps}
      />
      <p>{text}</p>
    </div>
  )
}
EmptyState.displayName = "EmptyState"

export { EmptyState }
