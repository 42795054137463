import { CheckIcon, GemIcon } from "lucide-react"

import { getAvatarUrlByAddress } from "@/utils/profile-utils"
import { calculateLevel, formatLevel } from "@/utils/rewards-utils"
import { cn } from "@/utils/tw-utils"
import { shortenAddress } from "@/utils/venom-utils"
import { appConfig, LevelConfig } from "@/config/app-config"
import { zeroAddress } from "@/config/venom-config"
import { useVenom } from "@/providers/venom-provider"
import { useProfile } from "@/hooks/use-profile"
import { attributeTitleStyle } from "@/components/ui/attribute"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Diode } from "@/components/ui/diode"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card"
import { Progress } from "@/components/ui/progress"
import { Separator } from "@/components/ui/separator"
import { LevelBadge } from "@/components/common/level-badge"

interface ProfileProgressProps extends React.ComponentPropsWithoutRef<"div"> {}

const ProfileProgress = ({ className, ...props }: ProfileProgressProps) => {
  const { account } = useVenom()
  const { profile } = useProfile(account?.address)

  const lastLevel = appConfig.levels[appConfig.levels.length - 1]

  return (
    <div
      className={cn(
        "rounded-md border bg-gradient-to-b from-accent/30 to-accent/0",
        className
      )}
      {...props}
    >
      <div className="flex items-center justify-between px-6 pt-6 md:px-10 md:pt-10">
        <div className="flex items-center gap-2">
          <Avatar className="mr-2 block h-12 w-12 md:h-12 md:w-12">
            <AvatarImage
              src={getAvatarUrlByAddress(
                profile ? profile.walletAddress : zeroAddress
              )}
              alt=""
            />
          </Avatar>

          <div className="flex flex-col items-start justify-center">
            <span className="text-base font-medium leading-none md:text-lg">
              {shortenAddress(profile ? profile.walletAddress : zeroAddress)}
            </span>

            <LevelBadge
              rewards={profile ? profile.rewards : 0}
              className="mt-2 md:mt-0"
            />
          </div>
        </div>

        <div>
          <div className="flex h-5 w-full flex-none items-center justify-end gap-2 text-base font-medium md:text-lg">
            <GemIcon className="h-5 w-5 text-secondary" />
            {profile ? profile.rewards : 0}
          </div>

          <span
            className={cn(
              "mb-1 mt-2 block w-full text-right md:mb-0",
              attributeTitleStyle
            )}
          >
            Your Gems
          </span>
        </div>
      </div>

      <div className="overflow-y-auto">
        <div className="min-w-[740px] px-6 md:px-10">
          <div className="px-4 pb-20 pt-36 md:px-6 md:pt-[13.25rem]">
            <div className="relative">
              <Progress
                value={
                  profile
                    ? ((profile.rewards - 100) / (lastLevel.minRewards - 100)) *
                      100
                    : 0
                }
              />

              {appConfig.levels.map((level) => (
                <ProfileProgressStep
                  key={level.index}
                  level={level}
                  first={level.index === 0}
                  completed={
                    profile ? profile.rewards >= level.minRewards : false
                  }
                  current={
                    profile
                      ? calculateLevel(profile.rewards) === level.index
                      : false
                  }
                  percentage={
                    ((level.minRewards - 100) / (lastLevel.minRewards - 100)) *
                    100
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileProgress.displayName = "ProfileProgress"

interface ProfileProgressStepProps
  extends React.ComponentPropsWithoutRef<"div"> {
  level: LevelConfig
  first: boolean
  completed: boolean
  current: boolean
  percentage: number
}

const ProfileProgressStep = ({
  level,
  percentage,
  completed,
  first,
  current,
  ...props
}: ProfileProgressStepProps) => {
  const { title, description, image, minRewards } = level
  const formattedLevel = formatLevel(minRewards)

  return (
    <HoverCard openDelay={500} closeDelay={250}>
      <HoverCardTrigger asChild>
        <div
          className={cn(
            "group absolute top-0 -translate-x-1/2 -translate-y-[calc(50%_-_0.1875rem)]"
          )}
          style={{
            left: `${percentage}%`,
          }}
          {...props}
        >
          <div className="relative">
            <div className="absolute  left-1/2 top-2 -z-20 h-[7rem] w-20 -translate-x-1/2 -translate-y-full rounded-sm border opacity-0 transition-opacity group-hover:opacity-100 md:h-40 md:w-28" />

            <div className="absolute left-1/2 top-2 -z-20 h-8 w-24 -translate-x-1/2 -translate-y-full animate-breath md:h-12 md:w-32">
              <div className="h-full w-full bg-gradient-radial from-primary/90 to-primary/0 to-60% opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
            </div>

            <div className="absolute left-1/2 top-0 flex h-[7rem] w-24 -translate-x-1/2 -translate-y-full select-none flex-col items-end justify-end pb-2 md:h-40 md:w-32 md:pb-4">
              <img src={image} alt="" className="mx-auto block h-full w-auto" />
            </div>

            <div className="absolute left-1/2 top-1/2 -z-20 h-8 w-8 -translate-x-1/2 -translate-y-1/2 rounded-full bg-background" />

            {current && (
              <Diode className="absolute left-1/2 top-1/2 -z-10 h-6 w-6 -translate-x-1/2 -translate-y-1/2" />
            )}

            <div
              className={cn(
                "flex h-6 w-6 items-center justify-center rounded-full border-background",
                {
                  "bg-frozen": !completed,
                  "bg-gradient-to-t from-secondary/100 to-primary/100":
                    completed,
                }
              )}
            >
              {completed ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <span className="text-[10px] font-medium transition-colors group-hover:text-primary">
                  {formattedLevel}
                </span>
              )}
            </div>

            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full pt-0.5 text-center uppercase">
              <Badge
                variant="frozen"
                className="whitespace-nowrap px-1.5 text-center text-[10px] font-medium leading-tight"
              >
                {minRewards}+
              </Badge>
            </div>
          </div>
        </div>
      </HoverCardTrigger>

      <HoverCardContent className="mt-0.5 w-96">
        <div className="flex items-center justify-between gap-3">
          <h6 className="font-medium">{title}</h6>
        </div>

        <p className="mt-3 text-sm text-muted-foreground">{description}</p>

        <Separator className="my-4" />

        <div className="flex items-center justify-between gap-3">
          <LevelBadge rewards={minRewards} />

          <div className=" flex items-center gap-2">
            <GemIcon className="h-4 w-4 text-secondary" />
            <span className="text-xs font-medium">{minRewards}+</span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}

export { ProfileProgress }
