import { useMemo } from "react"
import { faqService } from "@/services/faq-service"
import { useQuery } from "react-query"

export const useFaqQuery = () => {
  const queryResult = useQuery("faq", async () => {
    return faqService.getFaq()
  })

  return useMemo(() => {
    return {
      faq: queryResult.data,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
    }
  }, [queryResult.data, queryResult.error, queryResult.isLoading])
}
