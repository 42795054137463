const levelRomanSymbol: Record<number, string> = {
  0: "0",
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
  9: "IX",
  10: "X",
}

export function calculateLevel(rewards: number) {
  return Math.floor(rewards / 100)
}

export function formatLevel(rewards: number) {
  return levelRomanSymbol[calculateLevel(rewards)]
}
