import * as React from "react"

import { cn } from "@/utils/tw-utils"
import { Spinner, SpinnerProps } from "@/components/ui/spinner"

export interface LoadingProps extends React.ComponentPropsWithoutRef<"span"> {
  text?: string
  _spinner?: SpinnerProps
}

const Loading = React.forwardRef<HTMLSpanElement, LoadingProps>(
  ({ text, className, _spinner, ...props }, ref) => {
    const { className: spinnerClassName, ..._spinnerProps } = _spinner || {}

    return (
      <span
        className={cn(
          "inline-flex h-full w-full animate-pulse items-center justify-center gap-2 text-sm font-medium",
          className
        )}
        ref={ref}
        {...props}
      >
        <Spinner className={spinnerClassName} {..._spinnerProps} />
        <span>{text}</span>
      </span>
    )
  }
)
Loading.displayName = "Loading"

export { Loading }
