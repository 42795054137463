import { GhostIcon, RocketIcon } from "lucide-react"
import { Link } from "react-router-dom"

import { Button } from "@/components/ui/button"

const NoMatchPage = () => {
  return (
    <div className="container flex h-screen items-center justify-center">
      <div className="flex w-full max-w-md flex-col items-center justify-center rounded-md border bg-gradient-to-b from-accent/30 to-accent/0 px-8 py-16 text-center">
        <GhostIcon className="mb-8 h-8 w-8 animate-tada" />

        <h2 className="text-5xl font-bold">404</h2>
        <h4 className="mt-2">Nothing to see here!</h4>

        <Button asChild variant="gradient" className="mt-8" size="lg">
          <Link to="/">
            <RocketIcon className="mr-2 h-4 w-4" /> Launchpad
          </Link>
        </Button>
      </div>
    </div>
  )
}

NoMatchPage.displayName = "NoMatchPage"

export { NoMatchPage }
