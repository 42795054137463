import { apiService } from "@/services/api-service"

import {
  LaunchpadCollection,
  LaunchpadCollectionWithDetails,
} from "@/types/launchpad-collection"

import { wait } from "@/utils/wait"
import { appConfig } from "@/config/app-config"
import {
  launchpadCollectionAllowlistMock,
  launchpadCollectionsMock,
  launchpadCollectionWithDetailsMock,
} from "@/config/mocks/launchpad-collections-mocks"

type GetLaunchpadCollectionParams = {
  search?: string
}

const getMockedLaunchpadCollections = (
  params?: GetLaunchpadCollectionParams
): LaunchpadCollection[] => {
  return params?.search
    ? launchpadCollectionsMock.filter((collection) => {
        return collection.name
          .toLowerCase()
          .includes(params.search!.toLowerCase())
      })
    : launchpadCollectionsMock
}

const getMockedLaunchpadCollectionDetails = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  slug: string
): LaunchpadCollectionWithDetails => {
  return {
    ...launchpadCollectionWithDetailsMock,
    slug,
  }
}

const getLaunchpadCollections = async (
  params?: GetLaunchpadCollectionParams
): Promise<LaunchpadCollection[]> => {
  if (appConfig.useMocks) {
    wait(200)
    return getMockedLaunchpadCollections(params)
  }

  const res = await apiService.get<LaunchpadCollection[]>("/collections")
  return res.data
}

const getLaunchpadCollectionDetails = async (
  slug: string
): Promise<LaunchpadCollectionWithDetails> => {
  if (appConfig.useMocks) {
    wait(200)
    return getMockedLaunchpadCollectionDetails(slug)
  }

  const res = await apiService.get<LaunchpadCollectionWithDetails>(
    `/collections/${slug}/slug`
  )
  return res.data
}

const getLaunchpadCollectionAllowlist = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  url: string
): Promise<Record<string, number>> => {
  await wait(1000)
  return launchpadCollectionAllowlistMock
}

export const launchpadCollectionsService = {
  getLaunchpadCollections,
  getLaunchpadCollectionDetails,
  getLaunchpadCollectionAllowlist,
}
