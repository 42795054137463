import React from "react"

import { MediaSize, Media as MediaType } from "@/types/media"

import { cn } from "@/utils/tw-utils"
import { Image, ImageProps } from "@/components/ui/image"
import { Video, VideoProps } from "@/components/ui/video"

type MediaProps = {
  media: MediaType
  size: MediaSize
} & (VideoProps | ImageProps)

const Media = React.forwardRef<HTMLVideoElement | HTMLImageElement, MediaProps>(
  ({ media, size, className, ...props }, ref) => {
    return media.type === "video" ? (
      <Video
        className={cn("h-full w-full", className)}
        src={media.sizes[size]}
        autoPlay
        loop
        muted
        playsInline
        ref={ref as React.ForwardedRef<HTMLVideoElement>}
        {...(props as VideoProps)}
      />
    ) : (
      <Image
        src={media.sizes[size]}
        alt={media.alt}
        ref={ref as React.ForwardedRef<HTMLImageElement>}
        className={cn("h-full w-full", className)}
        {...(props as ImageProps)}
      />
    )
  }
)
Media.displayName = "Media"

export { Media }
