import React from "react"

import { TeamMember } from "@/types/team-member"

import { cn } from "@/utils/tw-utils"
import { ExternalLinks } from "@/components/ui/external-links"
import { Image } from "@/components/ui/image"
import { StyledHtml } from "@/components/ui/styled-html"

type TeamProps = React.ComponentPropsWithoutRef<"ul"> & {
  team: TeamMember[]
}

const Team = React.forwardRef<HTMLUListElement, TeamProps>(
  ({ team, className }: TeamProps, ref) => {
    return (
      <ul
        ref={ref}
        className={cn("grid grid-cols-1 md:gap-3 lg:grid-cols-2", className)}
      >
        {team.map((teamMember, index) => (
          <TeamEntry key={index} index={index} teamMember={teamMember} />
        ))}
      </ul>
    )
  }
)
Team.displayName = "Team"

type TeamEntryProps = React.ComponentPropsWithoutRef<"div"> & {
  teamMember: TeamMember
  index: number
}

const TeamEntry = ({ teamMember, index, className }: TeamEntryProps) => {
  const { name, title, description, avatar } = teamMember

  const hasDescription = Boolean(description)

  return (
    <li
      className={cn(
        "flex gap-4 border-b py-3 first:pt-0 last:border-b-0 md:gap-6 md:rounded-md md:border md:px-3 md:py-3 md:first:pt-3 md:last:border-b",
        className
      )}
    >
      <div>
        <div className=" h-14 w-14 overflow-hidden rounded-full md:rounded-sm lg:h-24 lg:w-24">
          {avatar ? (
            <Image src={avatar} className="h-full w-full object-cover" />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-frozen/60 text-3xl text-primary backdrop-blur md:text-4xl"></div>
          )}
        </div>
      </div>
      <div>
        <h3 className="max-w-lg text-base font-semibold">{name}</h3>
        {title ? (
          <div className="mt-1 text-xs font-medium uppercase leading-tight text-foreground/60">
            {title}
          </div>
        ) : null}

        {hasDescription && (
          <StyledHtml html={description!} className="mt-3 max-w-2xl text-sm" />
        )}

        <ExternalLinks
          links={teamMember.links}
          _link={{
            className: "h-9 px-3",
          }}
          className="mt-3"
        />
      </div>
    </li>
  )
}
TeamEntry.displayName = "TeamEntry"

export { Team }
