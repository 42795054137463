import React, { useEffect } from "react"
import { Address } from "everscale-inpage-provider"
import {
  ChevronDownIcon,
  CopyIcon,
  HelpCircle,
  LogOutIcon,
  PlusIcon,
} from "lucide-react"
import { Link } from "react-router-dom"
import { useCopyToClipboard } from "usehooks-ts"

import { Profile } from "@/types/profile"

import { getAvatarUrlByAddress } from "@/utils/profile-utils"
import { cn } from "@/utils/tw-utils"
import { shortenAddress } from "@/utils/venom-utils"
import { useVenom } from "@/providers/venom-provider"
import { useProfile } from "@/hooks/use-profile"
import { useBalance } from "@/hooks/venom/use-balance"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import { Balance } from "@/components/ui/balance"
import { Button } from "@/components/ui/button"
import { Menu, MenuButton } from "@/components/ui/menu"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Separator } from "@/components/ui/separator"
import { Spinner } from "@/components/ui/spinner"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { LevelBadge } from "@/components/common/level-badge"

type ConnectProps = Omit<React.ComponentPropsWithoutRef<"div">, "children"> & {
  _popoverContent?: React.ComponentPropsWithRef<typeof PopoverContent>
  _profileButton?: Omit<React.ComponentPropsWithRef<typeof Button>, "onClick">
  _connectButtonWrapper?: React.ComponentPropsWithRef<"div">
  onConnectAttempt?: () => void
  onDisconnectAttempt?: () => void
}

const Connect = ({
  _popoverContent = {},
  _profileButton = {},
  _connectButtonWrapper = {},
  className,
  ...props
}: ConnectProps) => {
  const { account, connect, disconnect } = useVenom()
  const { profile } = useProfile(account?.address)
  const { balance } = useBalance()

  const { className: popoverContentClassName, ..._popoverContentProps } =
    _popoverContent

  let children: React.ReactNode | null = null

  if (profile && account) {
    children = (
      <Popover>
        <PopoverTrigger asChild>
          <ProfileMenuTrigger profile={profile} {..._profileButton} />
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-[13.25rem] p-3", popoverContentClassName)}
          {..._popoverContentProps}
        >
          <div className="flex flex-col gap-1">
            <CopyableAddress address={account.address} />
            <AccountBalance balance={balance ?? "-"} />
            <ProfileLevel rewards={profile.rewards} />
          </div>

          <Separator className="my-3" />

          <ProfileMenu onDisconnect={disconnect} />
        </PopoverContent>
      </Popover>
    )
  } else if (account) {
    children = (
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex h-10 w-10 items-center justify-center">
          <Spinner />
        </div>
      </div>
    )
  } else {
    children = (
      <div {..._connectButtonWrapper}>
        <Button variant="gradient" className="w-full" onClick={connect}>
          Connect wallet
        </Button>
      </div>
    )
  }

  return (
    <div className={cn("flex items-center", className)} {...props}>
      {children}
    </div>
  )
}
Connect.displayName = "Connect"

type ProfileMenuTriggerProps = React.ComponentPropsWithoutRef<typeof Button> & {
  profile: Profile
}

const ProfileMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  ProfileMenuTriggerProps
>(({ profile, className, ...props }, ref) => {
  return (
    <Button
      variant="ghost"
      className={cn("group h-auto rounded-md p-3 font-normal", className)}
      ref={ref}
      {...props}
    >
      <Avatar className="mr-2 h-9 w-9">
        <AvatarImage
          src={getAvatarUrlByAddress(profile.walletAddress)}
          alt=""
        />
      </Avatar>

      <span className="mr-2 inline-flex flex-col items-start justify-start gap-0.5">
        <span className="block w-28 text-left font-medium leading-tight">
          {shortenAddress(profile.walletAddress)}
        </span>

        <LevelBadge
          rewards={profile.rewards}
          variant="frozen"
          className="px-1.5 text-[10px] leading-tight"
        />
      </span>

      <ChevronDownIcon
        className="relative top-[1px] ml-auto h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
        aria-hidden="true"
      />
    </Button>
  )
})
ProfileMenuTrigger.displayName = "ProfileMenuTrigger"

const AccountBalance = ({ balance }: { balance: string }) => {
  return (
    <div className="flex w-full items-center justify-between gap-2 pl-3 pr-0">
      <Balance className="text-sm font-medium" value={balance} />

      <Button
        variant="ghost"
        size="icon"
        className="h-10 w-10 text-foreground/60"
        asChild
      >
        <a href="https://venom.network" target="_blank" rel="noreferrer">
          <PlusIcon className="h-4 w-4" />
          <span className="sr-only">Copy address</span>
        </a>
      </Button>
    </div>
  )
}
AccountBalance.displayName = "AccountBalance"

const CopyableAddress = ({ address }: { address: Address | string }) => {
  const [, copy] = useCopyToClipboard()
  const [copied, setCopied] = React.useState(false)
  const timeoutIdRef = React.useRef<number>()

  const handleCopy = () => {
    copy(address.toString())

    setCopied(true)

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }

    timeoutIdRef.current = window.setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current)
      }
    }
  }, [])

  return (
    <div className="flex w-full items-center justify-between gap-2 pl-3 pr-0">
      <div className="text-sm font-medium">{shortenAddress(address, 8)}</div>

      <Tooltip open={copied}>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="h-10 w-10 text-foreground/60"
            onClick={handleCopy}
          >
            <CopyIcon className="h-4 w-4" />
            <span className="sr-only">Copy address</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Copied!</TooltipContent>
      </Tooltip>
    </div>
  )
}
CopyableAddress.displayName = "CopyableAddress"

const ProfileMenu = ({ onDisconnect }: { onDisconnect: () => void }) => {
  return (
    <Menu className="flex-col items-start justify-start">
      <MenuButton
        title="Disconnect"
        icon={LogOutIcon}
        className="w-full justify-start px-3"
        onClick={onDisconnect}
      />
    </Menu>
  )
}
ProfileMenu.displayName = "ProfileMenu"

const ProfileLevel = ({ rewards }: { rewards: number }) => {
  return (
    <div className="flex w-full items-center justify-between gap-2 pl-3 pr-0">
      <LevelBadge rewards={rewards} />

      <Button
        variant="ghost"
        size="icon"
        className="h-10 w-10 text-foreground/60"
        asChild
      >
        <Link to="/rewards">
          <HelpCircle className="h-4 w-4" />
          <span className="sr-only">About levels</span>
        </Link>
      </Button>
    </div>
  )
}
ProfileLevel.displayName = "ProfileLevel"

export type { ConnectProps }
export { Connect }
