import React from "react"

import { cn } from "@/utils/tw-utils"
import { Diode } from "@/components/ui/diode"

type LiveIndicatorProps = React.ComponentPropsWithoutRef<"span">

const LiveIndicator = ({ className, ...props }: LiveIndicatorProps) => {
  return (
    <span
      className={cn("flex items-center gap-2 whitespace-nowrap", className)}
      {...props}
    >
      <Diode className="-mx-0.5" />
      Live
    </span>
  )
}
LiveIndicator.displayName = "LiveIndicator"

export { LiveIndicator }
