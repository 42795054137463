import { useEffect, useRef } from "react"
import clsx from "clsx"
import {
  GemIcon,
  InfoIcon,
  MenuIcon,
  RocketIcon,
  SearchIcon,
  XIcon,
} from "lucide-react"
import { Link } from "react-router-dom"

import { cn } from "@/utils/tw-utils"
import { useLayout } from "@/providers/layout-provider"
import { Button } from "@/components/ui/button"
import { Menu, MenuLink } from "@/components/ui/menu"
import { Logo } from "@/components/icons/logo"
import { Connect } from "@/components/layout/connect"
import { Search } from "@/components/layout/search"

const Header = () => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { isSearchActive, toggleDrawer, toggleSearch } = useLayout()

  useEffect(() => {
    if (isSearchActive) {
      searchInputRef.current?.focus()
    }
  }, [isSearchActive])

  return (
    <header className="container relative z-10 my-1 flex h-16 w-full items-center justify-between px-3 md:px-6 lg:my-3">
      <div className="flex w-full items-center">
        <Button
          variant="ghost"
          size="icon"
          className={cn("lg:hidden", {
            hidden: isSearchActive,
          })}
          onClick={() => toggleDrawer()}
        >
          <span className="sr-only">Toggle menu</span>
          <MenuIcon className="h-6 w-6" />
        </Button>

        <Link
          to="/launchpad"
          className={cn(
            "ml-4 mr-4 flex h-10 w-[10rem] flex-none items-center rounded-md outline-none md:ml-4 lg:ml-0",
            {
              hidden: isSearchActive,
            }
          )}
        >
          <span className="sr-only">Home</span>
          <Logo className="w-full" />
        </Link>

        <Menu className="hidden gap-4 lg:flex">
          <MenuLink to="/launchpad" title="Launchpad" icon={RocketIcon} />
          <MenuLink to="/support" title="Support" icon={InfoIcon} />
        </Menu>

        <Search
          ref={searchInputRef}
          className={cn("max-w-72 mr-4 w-full lg:ml-4 lg:flex", {
            hidden: !isSearchActive,
          })}
        />
      </div>

      <div className="flex h-full items-center gap-4">
        <MenuLink
          to="/rewards"
          title="Rewards"
          icon={GemIcon}
          pulse
          className="hidden gap-2 text-foreground lg:flex [&_[data-pulse]]:bg-secondary [&_svg]:text-secondary"
        />

        <Button
          variant="ghost"
          size="icon"
          aria-label="Toggle search"
          className="lg:hidden"
          onClick={() => toggleSearch()}
        >
          <span className="sr-only">Toggle search</span>
          <SearchIcon
            className={clsx("h-6 w-6", {
              hidden: isSearchActive,
            })}
          />
          <XIcon
            className={clsx("h-6 w-6", {
              hidden: !isSearchActive,
            })}
          />
        </Button>

        <Connect className="hidden h-full md:flex" />
      </div>
    </header>
  )
}

Header.displayName = "Header"

export { Header }
