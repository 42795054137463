import { useState } from "react"

import { useVenom } from "@/providers/venom-provider"
import { useProfile } from "@/hooks/use-profile"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { AddEmailForm } from "@/components/rewards/add-email-form"
import { VerifyEmailForm } from "@/components/rewards/verify-email-form"

interface EmailSubscriptionDialogProps {
  onSuccess: () => void
  onClose: () => void
}

const EmailSubscriptionDialog = ({
  onSuccess,
  onClose,
}: EmailSubscriptionDialogProps) => {
  const { account } = useVenom()
  const { profile } = useProfile(account?.address)

  const [step, setStep] = useState<1 | 2>(1)

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="px-6 py-10 sm:px-10 sm:py-12">
        <DialogHeader>
          <DialogTitle className="mb-2">
            {step === 1 ? (
              <>Add your email address</>
            ) : (
              <>Verify your email address</>
            )}
          </DialogTitle>

          <DialogDescription className="leading-relaxed">
            {step === 1
              ? "Enter your email below, and we will send you a verification code via email."
              : "Please enter the verification code we sent to your email below."}
          </DialogDescription>
        </DialogHeader>

        <div className="mt-5">
          {profile ? (
            step === 1 ? (
              <AddEmailForm
                profile={profile}
                onSuccess={() => {
                  setStep(2)
                }}
              />
            ) : (
              <VerifyEmailForm profile={profile} onSuccess={onSuccess} />
            )
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  )
}

EmailSubscriptionDialog.displayName = "EmailSubscriptionDialog"

export { EmailSubscriptionDialog }
