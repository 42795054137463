import { useMemo } from "react"

import {
  LaunchpadCollectionStage,
  LaunchpadCollectionStatus,
} from "@/types/launchpad-collection"

import { timestampToDate } from "@/utils/date-utils"

export type LaunchpadCollectionStageStatusResult = {
  status: Omit<
    LaunchpadCollectionStatus,
    LaunchpadCollectionStatus.Paused | LaunchpadCollectionStatus.SoldOut
  >
  startDate: Date
  endDate: Date
}

export const getLaunchStageStatus = (
  stage: LaunchpadCollectionStage
): LaunchpadCollectionStageStatusResult => {
  const now = new Date()

  const startDate = timestampToDate(stage.startTime)
  const endDate = timestampToDate(stage.endTime)

  let status = LaunchpadCollectionStatus.Ended

  if (
    now.getTime() > startDate.getTime() &&
    now.getTime() < endDate.getTime()
  ) {
    status = LaunchpadCollectionStatus.Live
  }

  if (now.getTime() < startDate.getTime()) {
    status = LaunchpadCollectionStatus.Upcoming
  }

  return {
    status,
    startDate,
    endDate,
  }
}

export const useLaunchStageStatus = (stage: LaunchpadCollectionStage) => {
  return useMemo(() => getLaunchStageStatus(stage), [stage])
}
