import { LaunchpadCollectionWithDetails } from "@/types/launchpad-collection"

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { FaqEntries } from "@/components/common/faq"
import { Roadmap } from "@/components/common/roadmap"
import { Team } from "@/components/common/team"
import { LaunchDescription } from "@/components/launchpad/launch-description"

type LaunchOverviewProps = {
  collection: LaunchpadCollectionWithDetails
}

const LaunchOverview = ({ collection }: LaunchOverviewProps) => {
  const hasRoadmap = Boolean(collection.roadmap && collection.roadmap.length)
  const hasTeam = Boolean(collection.team && collection.team.length)
  const hasFaq = Boolean(collection.faq && collection.faq.length)

  return (
    <div className="mt-10">
      <Tabs defaultValue="description" className="relative">
        <TabsList className="border-b">
          <TabsTrigger value="description" className="h-12 px-5">
            About
          </TabsTrigger>
          {hasRoadmap && (
            <TabsTrigger value="roadmap" className="h-12 px-5">
              Roadmap
            </TabsTrigger>
          )}
          {hasTeam && (
            <TabsTrigger value="team" className="h-12 px-5">
              Team
            </TabsTrigger>
          )}
          {hasFaq && (
            <TabsTrigger value="faq" className="h-12 px-5">
              FAQ
            </TabsTrigger>
          )}
        </TabsList>

        <TabsContent value="description" className="mt-8">
          <LaunchDescription collection={collection} />
        </TabsContent>

        {hasRoadmap && (
          <TabsContent value="roadmap" className="mt-8 max-w-4xl">
            <Roadmap roadmap={collection.roadmap!} />
          </TabsContent>
        )}
        {hasTeam && (
          <TabsContent value="team" className="mt-8">
            <Team team={collection.team!} />
          </TabsContent>
        )}
        {hasFaq && (
          <TabsContent value="faq" className="mt-6 max-w-4xl md:mt-8">
            <FaqEntries entries={collection.faq!} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  )
}
LaunchOverview.displayName = "LaunchOverview"

export { LaunchOverview }
