import React, { useRef, useState } from "react"
import { ChevronDownIcon } from "lucide-react"
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts"

import { cn } from "@/utils/tw-utils"
import { Button } from "@/components/ui/button"
import { StyledHtml } from "@/components/ui/styled-html"

type SeeMoreProps = {
  text: string
  lineClamp?: 2 | 3 | 4 | 5 | 6
} & React.ComponentPropsWithoutRef<"div">

const SeeMore = React.forwardRef<HTMLDivElement, SeeMoreProps>(
  ({ text, lineClamp, className, ...props }: SeeMoreProps, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const [hasMore, setHasMore] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const handleSizeChange = () => {
      if (!containerRef.current) return

      const element = containerRef.current
      if (element.clientHeight < element.scrollHeight && !hasMore) {
        setHasMore(true)
      }
    }

    useIsomorphicLayoutEffect(() => {
      handleSizeChange()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEventListener("resize", handleSizeChange)

    return (
      <div
        ref={ref}
        className={cn("flex flex-col items-start", className)}
        {...props}
      >
        <StyledHtml
          html={text}
          ref={containerRef}
          className={cn("max-w-xl leading-relaxed", {
            "line-clamp-2": lineClamp === 2 && !isOpen,
            "line-clamp-3": lineClamp === 3 && !isOpen,
            "line-clamp-4": lineClamp === 4 && !isOpen,
            "line-clamp-5": lineClamp === 5 && !isOpen,
            "line-clamp-6": lineClamp === 6 && !isOpen,
            "line-clamp-none [&>*:first-child]:block": isOpen,
            "[&>*:first-child]:my-0 [&>*:first-child]:py-0 [&>*:not(:first-child)]:hidden":
              !isOpen,
          })}
        />

        {hasMore && (
          <Button
            variant="link"
            className="mt-4 h-auto gap-1 px-0 py-0 font-normal text-muted-foreground"
            onClick={() => setIsOpen(!isOpen)}
          >
            See {isOpen ? "less" : "more"}{" "}
            <ChevronDownIcon
              className={cn("h-4 w-4 transition-transform", {
                "rotate-180": isOpen,
              })}
            />
          </Button>
        )}
      </div>
    )
  }
)
SeeMore.displayName = "SeeMore"

export { SeeMore }
