import level0Image from "@/assets/images/levels/level-0.png"
import level1Image from "@/assets/images/levels/level-1.png"
import level2Image from "@/assets/images/levels/level-2.png"
import level3Image from "@/assets/images/levels/level-3.png"
import level4Image from "@/assets/images/levels/level-4.png"
import level5Image from "@/assets/images/levels/level-5.png"
import level6Image from "@/assets/images/levels/level-6.png"
import level7Image from "@/assets/images/levels/level-7.png"
import level8Image from "@/assets/images/levels/level-8.png"
import level9Image from "@/assets/images/levels/level-9.png"

export interface LevelConfig {
  title: string
  description: string
  index: number
  minRewards: number
  maxRewards: number
  image: string
  chanceBoost?: number
}

export const appConfig = {
  useMocks: process.env.REACT_APP_USE_MOCKS === "true",
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,

  titleTemplate: "{{pageTitle}} | Gateway.io",

  levels: [
    {
      title: "Newcomer",
      description:
        "Welcome to Gateway.io! You've just embarked on your NFT adventure and are taking your first steps in exploring the world of digital collectibles.",
      index: 1,
      minRewards: 100,
      maxRewards: 199,
      image: level0Image,
    },
    {
      title: "Explorer",
      description:
        "You're no longer just a newcomer; you're now an explorer, delving into the exciting possibilities of NFTs and blockchain technology.",
      index: 2,
      minRewards: 200,
      maxRewards: 299,
      image: level1Image,
    },
    {
      title: "Contributor",
      description:
        "You're actively contributing to the Gateway.io community, whether it's through insightful discussions, helpful guides, or valuable feedback.",
      index: 3,
      minRewards: 300,
      maxRewards: 399,
      image: level2Image,
    },
    {
      title: "NFT Enthusiast",
      description:
        "Your passion for NFTs shines through, and you're deeply immersed in the world of digital collectibles, showcasing your dedication and enthusiasm.",
      index: 4,
      minRewards: 400,
      maxRewards: 499,
      image: level3Image,
    },
    {
      title: "Premium Collector",
      description:
        "Your NFT collection is impressive, and you've become a notable figure in the NFT community with your rare and valuable assets.",
      index: 5,
      minRewards: 500,
      maxRewards: 599,
      image: level4Image,
    },
    {
      title: "VIP Collector",
      description:
        "You're among the top-tier collectors on Gateway.io, with a collection that's not only extensive but also highly prestigious.",
      index: 6,
      minRewards: 600,
      maxRewards: 699,
      image: level5Image,
    },
    {
      title: "Elite Trader",
      description:
        "Your trading prowess sets you apart, and your actions in the marketplace have made you an influential figure in the NFT trading community.",
      index: 7,
      minRewards: 700,
      maxRewards: 799,
      image: level6Image,
    },
    {
      title: "Master Ambassador",
      description:
        "You've excelled in referring others to join Gateway.io, becoming a master ambassador who helps grow the platform's user base.",

      index: 8,
      minRewards: 800,
      maxRewards: 899,
      image: level7Image,
    },
    {
      title: "OG",
      description:
        "You've been with us for a long time, witnessing the evolution of Gateway.io, and your experience and wisdom are invaluable to the community.",
      index: 9,
      minRewards: 900,
      chanceBoost: 999,
      image: level8Image,
    },
    {
      title: "Legend",
      description:
        "You are a true legend, achieving the highest level of recognition on Gateway.io, and your influence extends far and wide in the NFT world. Congratulations on reaching the pinnacle of our community!",
      index: 10,
      minRewards: 1000,
      chanceBoost: Number.MAX_SAFE_INTEGER,
      image: level9Image,
    },
  ] as LevelConfig[],
} as const
