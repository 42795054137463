import { cn } from "@/utils/tw-utils"

export interface DiodeProps
  extends Omit<React.ComponentPropsWithRef<"span">, "children"> {
  state?: "on" | "off"
  color?: "primary" | "secondary" | "foreground" | "input"
}

function Diode({
  state = "on",
  color = "primary",
  className,
  ...props
}: DiodeProps) {
  return (
    <span
      className={cn(
        "relative flex h-3 w-3 items-center justify-center",
        className
      )}
      {...props}
    >
      {state === "on" && (
        <span
          className={cn(
            "absolute inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-75",
            `bg-${color}`
          )}
        ></span>
      )}

      <span
        className={cn(
          "relative inline-flex h-2 w-2 rounded-full",
          `bg-${color}`
        )}
      ></span>
    </span>
  )
}

export { Diode }
