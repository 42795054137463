import { useMemo } from "react"
import nftAbi from "@/abi/nft.abi.json"
import { Address } from "everscale-inpage-provider"
import { useQuery } from "react-query"

import { getAddress } from "@/utils/venom-utils"
import { useVenom } from "@/providers/venom-provider"

export const useTotalSupplyRead = (
  contractAddress: Address | string,
  options: {
    onError?: (error: Error) => void
    onSuccess?: (data: { totalSupply: number }) => void
  } = {}
) => {
  const { venomConnect } = useVenom()

  const queryResult = useQuery(
    [String(contractAddress), "totalSupply"],
    async () => {
      const venomStandaloneProvider = await venomConnect?.getStandalone()

      const contract = new venomStandaloneProvider.Contract(
        nftAbi,
        getAddress(contractAddress)
      )

      const outputs = await contract.methods
        .totalSupply({ answerId: 0 } as never)
        .call()

      return outputs as {
        count: string
      }
    },
    {
      enabled: !!venomConnect && !!contractAddress,
      retry: false,
      onError: options.onError,
      onSuccess: (data) => {
        options.onSuccess?.({
          totalSupply: parseInt(data.count),
        })
      },
    }
  )

  return useMemo(() => {
    return {
      totalSupply: queryResult.data?.count
        ? parseInt(queryResult.data?.count)
        : 0,
      error: queryResult.error,
      isLoading: queryResult.isLoading,
      isFetched: queryResult.isFetched,
      isError: queryResult.isError,
    }
  }, [
    queryResult.data,
    queryResult.error,
    queryResult.isLoading,
    queryResult.isFetched,
    queryResult.isError,
  ])
}
