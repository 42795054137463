import * as React from "react"

export interface VenomIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  variant?: "outline" | "solid"
}

const VenomIcon = React.forwardRef<SVGSVGElement, VenomIconProps>(
  ({ variant = "outline", ...props }, ref) => (
    <svg xmlSpace="preserve" viewBox="0 0 24 24" ref={ref} {...props}>
      <path
        d="M18.78 2.04c-.55-.09-1.11-.04-1.63.15-.52.18-.99.5-1.36.91-.71.79-.93 1.82-.76 2.85.15.93.36 1.87.4 2.77.06 1.35-.14 2.7-.61 3.97-.42 1.15-1.02 2.04-2.3 2.23-.1.02-.44.03-.54.03-.17 0-.34-.01-.51-.03-1.28-.2-1.89-1.09-2.3-2.24a10.32 10.32 0 0 1-.61-3.97c.05-.89.25-1.84.4-2.77.17-1.03-.05-2.06-.76-2.85-.35-.41-.82-.72-1.35-.9-.52-.19-1.08-.24-1.63-.15-1.65.27-2.74 1.73-2.75 3.25 0 .59.16 1.17.46 1.67.51.84 1.35 1.45 2.09 2.09.49.43 1.32 1.63 1.74 2.94.24.8.44 1.6.64 2.41.28 1.13.67 3.63.93 4.76.51 2.27 2.07 2.78 3.63 2.84h.06c1.56-.05 3.15-.57 3.66-2.84.25-1.13.64-3.63.93-4.76.2-.81.4-1.62.65-2.41.41-1.31 1.25-2.52 1.73-2.94.73-.64 1.57-1.25 2.08-2.09.31-.5.47-1.08.46-1.67-.01-1.53-1.1-2.98-2.75-3.25z"
        style={{
          fill: variant === "solid" ? "currentColor" : "none",
          stroke: variant === "outline" ? "currentColor" : "none",
          strokeWidth: 2,
          strokeOpacity: 1,
        }}
      />
    </svg>
  )
)

VenomIcon.displayName = "VenomIcon"

export { VenomIcon }
