export enum MediaType {
  Image = "image",
  Video = "video",
}

export enum MediaSize {
  Original = "original",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export interface Media {
  alt: string
  type: "image" | "video"
  sizes: Record<MediaSize, string>
}
