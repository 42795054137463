import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

type LayoutProviderProps = {
  children: React.ReactNode
}

type LayoutProviderState = {
  isDrawerOpen: boolean
  isSearchActive: boolean
  toggleDrawer: (open?: boolean) => void
  toggleSearch: (active?: boolean) => void
}

const initialState: LayoutProviderState = {
  isDrawerOpen: false,
  isSearchActive: false,
  toggleDrawer: () => {},
  toggleSearch: () => {},
}

const LayoutProviderContext = createContext<LayoutProviderState>(initialState)

export function LayoutProvider({ children, ...props }: LayoutProviderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)

  const toggleDrawer = useCallback((open?: boolean) => {
    setIsDrawerOpen((prev) => (open !== undefined ? open : !prev))
  }, [])

  const toggleSearch = useCallback((active?: boolean) => {
    setIsSearchActive((prev) => (active !== undefined ? active : !prev))
  }, [])

  const value: LayoutProviderState = useMemo(
    () => ({
      isDrawerOpen,
      isSearchActive,
      toggleDrawer,
      toggleSearch,
    }),
    [isDrawerOpen, isSearchActive, toggleDrawer, toggleSearch]
  )

  return (
    <LayoutProviderContext.Provider {...props} value={value}>
      {children}
    </LayoutProviderContext.Provider>
  )
}

export const useLayout = () => {
  const context = useContext(LayoutProviderContext)

  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider")
  }

  return context
}
