import { useEffect, useRef, useState } from "react"

import { cn } from "@/utils/tw-utils"
import { Skeleton } from "@/components/ui/skeleton"

export interface VideoProps
  extends Omit<React.ComponentPropsWithRef<"video">, "onCanPlay"> {
  onCanPlay?: () => void
  skeleton?: boolean
}

const Video = ({
  className,
  skeleton = true,
  onCanPlay,
  ...props
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [canPlay, setCanPlay] = useState(false)

  useEffect(() => {
    if (canPlay) {
      onCanPlay?.()
    }
  }, [canPlay, onCanPlay])

  useEffect(() => {
    const readyState = videoRef?.current?.readyState ?? 0
    if (readyState > 3) {
      setCanPlay(true)
      onCanPlay?.()
    }
  }, [onCanPlay, videoRef])

  return (
    <div
      className={cn("relative block", className)}
      data-state={canPlay ? "loaded" : undefined}
    >
      {!canPlay && skeleton && (
        <Skeleton className="absolute left-0 top-0 h-full w-full" />
      )}

      <video
        ref={videoRef}
        onCanPlay={() => {
          setCanPlay(true)
        }}
        className={cn(
          "h-full w-full object-cover opacity-0 transition-opacity duration-500",
          {
            "opacity-100": canPlay,
          }
        )}
        {...props}
      />
    </div>
  )
}
Video.displayName = "Video"

export { Video }
