import * as React from "react"

interface DiscordIconProps extends React.ComponentPropsWithoutRef<"svg"> {}

const DiscordIcon = React.forwardRef<SVGSVGElement, DiscordIconProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      ref={ref}
      {...props}
    >
      <path
        d="m7.9 18.1-1.2 2.4c-1.5-.6-3.1-1.3-4.7-2.3-.6-.4-.9-1-.9-1.7-.2-3.6.7-7.1 2.8-10.9.3-.5.7-.9 1.3-1 1.3-.5 2.1-.8 3.6-1l.9 1.6s.9-.3 2.4-.3 2.4.3 2.4.3l.9-1.6c1.5.2 2.3.6 3.6 1 .5.2 1 .6 1.3 1 2.1 3.8 2.9 7.3 2.9 10.9 0 .7-.4 1.3-.9 1.7-1.7 1.1-3.2 1.7-4.7 2.3l-1.2-2.4M6.1 17s3 1.5 5.9 1.5 5.9-1.5 5.9-1.5"
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeWidth: 2,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      />
      <path
        d="M8.3 10.4c-1.1 0-1.9 1-1.9 2.2s.9 2.2 1.9 2.2 1.9-1 1.9-2.2-.8-2.2-1.9-2.2zM15.700000000000001 10.4c-1.1 0-1.9 1-1.9 2.2s.9 2.2 1.9 2.2 1.9-1 1.9-2.2-.8-2.2-1.9-2.2z"
        style={{
          stroke: "none",
          fill: "currentColor",
        }}
      />
    </svg>
  )
)
DiscordIcon.displayName = "DiscordIcon"

export default DiscordIcon
